import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { AppRoutingModule } from '@app/app-routing.module'
import { AppComponent } from '@app/app.component'
import { SharedModule } from '@components/shared.module'
import { AppAvailability } from '@ionic-native/app-availability/ngx'
import { AppRate } from '@ionic-native/app-rate/ngx'
import { AppVersion } from '@ionic-native/app-version/ngx'
import { BackgroundMode } from '@ionic-native/background-mode/ngx'
import { Facebook } from '@ionic-native/facebook/ngx'
import { Geolocation } from '@ionic-native/geolocation/ngx'
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { Media } from '@ionic-native/media/ngx'
import { MusicControls } from '@ionic-native/music-controls/ngx'
import { Network } from '@ionic-native/network/ngx'
import { Push } from '@ionic-native/push/ngx'
import { Shake } from '@ionic-native/shake/ngx'
import { SocialSharing } from '@ionic-native/social-sharing/ngx'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { Vibration } from '@ionic-native/vibration/ngx'
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage'
import { EffectsModule } from '@ngrx/effects'
import { Store, StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { AddJokePage } from '@pages/tabs/entertainment/add-joke/add-joke.page'
import { ReduxTransFactory } from '@services/translate.service'
import { effects } from '@store/effects'
import { REDUCER_TOKEN, rootReducer } from '@store/reducers'
import { AddWordPage } from '@tabs/entertainment/add-word/add-word.page'
import { MandiDetailsPage } from '@tabs/entertainment/mandi-details/mandi-details.page'
import { PoemPage } from '@tabs/history/poem/poem.page'
import { SongPage } from '@tabs/history/song/song.page'
import { AddRequestPage } from '@tabs/radio/add-request/add-request.page'
import { environment } from 'src/environments/environment'
import { AddProverbPage } from './pages/tabs/entertainment/add-proverb/add-proverb.page'
import { AuthService } from './services/auth.service'

@NgModule({
    declarations: [
        // Components
        AppComponent,

        // Entry Component Page
        AddRequestPage,
        AddJokePage,
        AddWordPage,
        AddProverbPage,
        PoemPage,
        SongPage,
        MandiDetailsPage,
    ],
    entryComponents: [
        AddRequestPage,
        AddJokePage,
        AddWordPage,
        AddProverbPage,
        PoemPage,
        SongPage,
        MandiDetailsPage,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        SharedModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireModule.initializeApp(environment.firebase),
        EffectsModule.forRoot(effects),
        StoreModule.forRoot(REDUCER_TOKEN),
        StoreDevtoolsModule.instrument({}),
        TranslateModule.forRoot({
            // loader: {
            //     provide: TranslateLoader,
            //     useFactory: FBTransfactory,
            //     deps: [AngularFireDatabase]
            // },
            loader: {
                provide: TranslateLoader,
                useFactory: ReduxTransFactory,
                deps: [Store],
            },
        }),
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
    ],
    providers: [
        // services
        AuthService,
        // ionic native services
        AppAvailability,
        AppRate,
        AppVersion,
        BackgroundMode,
        Facebook,
        Push,
        Geolocation,
        GoogleAnalytics,
        InAppBrowser,
        Media,
        MusicControls,
        Network,
        Shake,
        SocialSharing,
        SplashScreen,
        StatusBar,
        Vibration,
        YoutubeVideoPlayer,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: REDUCER_TOKEN, useValue: rootReducer },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
