import { Injectable } from '@angular/core'
import { Push, PushOptions } from '@ionic-native/push/ngx'
import { environment } from 'src/environments/environment'

// to initialize push notifications
const pushOptions: PushOptions = {
    android: {
        senderID: environment.firebase.messagingSenderId,
        icon: 'notification_icon',
    },
    ios: {
        alert: 'true',
        badge: true,
        sound: 'false',
    },
    windows: {},
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
    constructor(private push: Push) {}

    async subscribe() {
        // to check if we have permission
        this.push.hasPermission().then((res: any) => {
            res.isEnabled
                ? console.log('We have permission to send push notifications')
                : console.log(
                      'We do not have permission to send push notifications'
                  )
        })

        this.push.init(pushOptions)

        // // Create a channel (Android O and above). You'll need to provide the id, description and importance properties.
        // this.push.createChannel({
        //     id: 'testchannel1',
        //     description: 'My first test channel',
        //     // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
        //     importance: 3
        // }).then(() => console.log('Channel created'));

        // // Delete a channel (Android O and above)
        // this.push.deleteChannel('testchannel1').then(() => console.log('Channel deleted'));

        // // Return a list of currently configured channels
        // this.push.listChannels().then((channels) => console.log('List of channels', channels));

        // const pushObject: PushObject = this.push.init(pushOptions);

        // pushObject.on('notification').subscribe((notification: any) => console.log('Received a notification', notification));

        // pushObject.on('registration').subscribe((registration: any) => console.log('Device registered', registration));

        // pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
    }
}
