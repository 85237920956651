import { Injectable } from '@angular/core'
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
    CREATE_REQUEST,
    getRequests,
    getRequestsSuccess,
    GET_REQUESTS,
} from '@store/actions/request.actions'
import { IRequest } from '@store/models/request.model'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class RequestEffects {
    private requestRef: AngularFireList<IRequest>

    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {
        this.requestRef = this.database.list('requests')
    }

    getRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_REQUESTS),
            switchMap(() => this.requestRef.valueChanges()),
            map((payload: IRequest[]) => getRequestsSuccess({ payload }))
        )
    )

    createRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CREATE_REQUEST),
            switchMap(({ payload }) => this.requestRef.push(payload)),
            map(() => getRequests())
        )
    )
}
