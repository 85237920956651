import { createAction, props } from '@ngrx/store'
import { IProverb } from '@store/models/proverb.model'
import { ISnapshot } from '@store/state'

export const GET_FIRST_PROVERB = 'GET_FIRST_PROVERB'
export const GET_FIRST_PROVERB_SUCCESS = 'GET_FIRST_PROVERB_SUCCESS'
export const GET_NEXT_PROVERB = 'GET_NEXT_PROVERB'
export const GET_NEXT_PROVERB_SUCCESS = 'GET_NEXT_PROVERB_SUCCESS'
export const GET_PREVIOUS_PROVERB = 'GET_PREVIOUS_PROVERB'
export const GET_PREVIOUS_PROVERB_SUCCESS = 'GET_PREVIOUS_PROVERB_SUCCESS'
export const CREATE_PROVERB = 'CREATE_PROVERB'
export const CREATE_PROVERB_SUCCESS = 'CREATE_PROVERB_SUCCESS'
export const getFirstProverb = createAction(GET_FIRST_PROVERB)
export const getFirstProverbSuccess = createAction(
    GET_FIRST_PROVERB_SUCCESS,
    props<{ payload: ISnapshot[] }>()
)
export const getNextProverb = createAction(
    GET_NEXT_PROVERB,
    props<{ key: string }>()
)
export const getNextProverbSuccess = createAction(
    GET_NEXT_PROVERB_SUCCESS,
    props<{ payload: ISnapshot[] }>()
)
export const getPreviousProverb = createAction(
    GET_PREVIOUS_PROVERB,
    props<{ key: string }>()
)
export const getPreviousProverbSuccess = createAction(
    GET_PREVIOUS_PROVERB_SUCCESS,
    props<{ payload: ISnapshot[] }>()
)
export const createProverb = createAction(
    CREATE_PROVERB,
    props<{ payload: IProverb }>()
)
export const createProverbSuccess = createAction(CREATE_PROVERB_SUCCESS)
