import { createReducer, on } from '@ngrx/store'
import * as Actions from '@store/actions/joke.actions'

export default createReducer(
    undefined,
    on(Actions.getFirstJokeSuccess, (state, { payload }) => {
        const joke = payload[1]

        return {
            ...state,
            firstJokeKey: joke && joke.key,
            currentJokeKey: joke && joke.key,
            nextJokeKey: joke && joke.prevKey,
            joke: joke && joke.payload.val(),
        }
    }),
    on(Actions.getNextJokeSuccess, (state, { payload }) => {
        const joke = payload[1]

        return {
            ...state,
            currentJokeKey: joke && joke.key,
            nextJokeKey: joke && joke.prevKey,
            previousJokeKey: joke && state.currentJokeKey,
            joke: joke && joke.payload.val(),
        }
    }),
    on(Actions.getPreviousJokeSuccess, (state, { payload }) => {
        const joke = payload[0]
        const lastJoke = payload[1]
        const firstIsCurrent = state.firstJokeKey === joke.key

        return {
            ...state,
            currentJokeKey: joke && joke.key,
            nextJokeKey: state.currentJokeKey,
            previousJokeKey: lastJoke && !firstIsCurrent && lastJoke.key,
            joke: joke && joke.payload.val(),
        }
    })
)
