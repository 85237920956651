import { createReducer, on } from '@ngrx/store'
import * as Actions from '@store/actions/player.actions'
import { find, flatten, get } from 'lodash'

export default createReducer(
    undefined,
    on(Actions.getServerSuccess, (state, { payload }) => {
        const server = payload
        const streams = [
            { label: 'Live Radio', url: server.liveUrl },
            { label: 'Raagni', url: server.raagniUrl },
            { label: 'Gaane', url: server.songsUrl },
        ]
        const currentStream = streams[0]

        return {
            ...state,
            server,
            streams,
            currentStream,
            audioSrc: currentStream.url,
        }
    }),
    on(Actions.getSongTitleSuccess, (state, { payload }) => {
        // Make an array and flatten
        // To handle single or multiple streams
        const currentSrc = find(flatten([get(payload, 'icestats.source')]), [
            'server_url',
            state.currentStream.url,
        ])

        return {
            ...state,
            songTitle: currentSrc ? currentSrc.title : 'गाणा का नाम कोनी बेरा',
        }
    }),
    on(Actions.updateIsPlayingSuccess, (state, { isPlaying }) => ({
        ...state,
        isPlaying,
    })),
    on(Actions.updateStreamSuccess, (state, { payload }) => ({
        ...state,
        currentStream: payload,
        audioSrc: payload.url,
    }))
)
