import { createAction, props } from '@ngrx/store'
import { IProgram } from '@store/models/program.model'

export const GET_PROGRAM = 'GET_PROGRAM'
export const GET_PROGRAM_SUCCESS = 'GET_PROGRAM_SUCCESS'
export const getProgram = createAction(GET_PROGRAM)
export const getProgramSuccess = createAction(
    GET_PROGRAM_SUCCESS,
    props<{ payload: IProgram[] }>()
)
