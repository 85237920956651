import { createReducer, on } from '@ngrx/store'
import * as Actions from '@store/actions/player-type.actions'

export default createReducer(
    undefined,
    on(Actions.setPlayerTypeSuccess, (state, { payload }) => ({
        ...state,
        all: payload,
    })),
    on(Actions.setDefaultPlayerType, (state, { playerType }) => ({
        ...state,
        default: playerType,
    })),
    on(Actions.setCurrentPlayerType, (state, { playerType }) => ({
        ...state,
        current: playerType,
    }))
)
