import { Injectable } from '@angular/core'
import { AppRate } from '@ionic-native/app-rate/ngx'
import { TranslateService } from '@ngx-translate/core'
import { ITranslatedValues } from '@app/interfaces'

@Injectable({ providedIn: 'root' })
export class RatingService {
    constructor(
        private appRate: AppRate,
        private translateService: TranslateService
    ) {}

    setAppRating(promptForRating: boolean = false): void {
        const translationKeys: Array<string> = [
            'APP_TITLE',
            'APP_RATE.MESSAGE',
            'APP_RATE.CANCEL_BUTTON',
            'APP_RATE.LATER_BUTTON',
            'APP_RATE.RATE_BUTTON',
            'APP_RATE.YES_BUTTON',
            'APP_RATE.NO_BUTTON',
            'APP_RATE.PROMPT_TITLE',
            'APP_RATE.FEEDBACK_PROMPT_TITLE',
        ]

        this.translateService
            .get(translationKeys)
            .subscribe((values: ITranslatedValues) => {
                const title: string = values['APP_TITLE']
                const message: string = values['APP_RATE.MESSAGE']
                const cancelButtonLabel: string =
                    values['APP_RATE.CANCEL_BUTTON']
                const laterButtonLabel: string = values['APP_RATE.LATER_BUTTON']
                const rateButtonLabel: string = values['APP_RATE.RATE_BUTTON']
                const yesButtonLabel = values['APP_RATE.YES_BUTTON']
                const noButtonLabel = values['APP_RATE.NO_BUTTON']
                const appRatePromptTitle = values['APP_RATE.PROMPT_TITLE']
                const feedbackPromptTitle =
                    values['APP_RATE.FEEDBACK_PROMPT_TITLE']

                // set custom display name
                this.appRate.preferences.displayAppName = title
                this.appRate.preferences.usesUntilPrompt = 3

                this.appRate.preferences.storeAppURL = {
                    ios: '1086344819',
                    android: 'market://details?id=com.radiokasoot.www',
                    // blackberry: 'appworld://content/[App Id]/'
                    // windows8: 'ms-windows-store:Review?name=<the Package Family Name of the application>'
                }

                // set locales
                this.appRate.preferences.customLocale = {
                    title: `Rate ${title}`,
                    message,
                    cancelButtonLabel,
                    laterButtonLabel,
                    rateButtonLabel,
                    yesButtonLabel,
                    noButtonLabel,
                    appRatePromptTitle,
                    feedbackPromptTitle,
                }

                if (promptForRating) {
                    this.appRate.promptForRating(true)
                }
            })
    }
}
