import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { getBlogPostSuccess, GET_BLOG_POST } from '@store/actions/blog.actions'
import { IBlogPost } from '@store/models/blog.model'
import { map, switchMap } from 'rxjs/operators'
import { BlogService } from '@services/blog.service'

@Injectable()
export class BlogEffects {
    constructor(private actions$: Actions, private blogService: BlogService) {}

    getBlogPost$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_BLOG_POST),
            switchMap(({ token }) => this.blogService.getNextPost(token)),
            map((payload: IBlogPost) => getBlogPostSuccess({ payload }))
        )
    )
}
