import { Action, createAction, props } from '@ngrx/store'
import { IWriterPayload } from '@store/models/writer.model'

export const GET_WRITERS = 'GET_WRITERS'
export const GET_WRITERS_SUCCESS = 'GET_WRITERS_SUCCESS'
export const getWriters = createAction(GET_WRITERS)
export const getWritersSuccess = createAction(
    GET_WRITERS_SUCCESS,
    props<{ writers: IWriterPayload[] }>()
)
