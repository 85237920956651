import { Action, createAction, props } from '@ngrx/store'
import { ISongPayload } from '@store/models/song.model'

export const GET_SONGS = 'GET_SONGS'
export const GET_SONGS_SUCCESS = 'GET_SONGS_SUCCESS'
export const getSongs = createAction(GET_SONGS, props<{ albumKey: string }>())
export const getSongsSuccess = createAction(
    GET_SONGS_SUCCESS,
    props<{ payload: ISongPayload[] }>()
)
