import { createAction, props } from '@ngrx/store'
import { ILanguage } from '@store/models/language.model'

export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS'
export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE'
export const SET_DEFAULT_LANGUAGE_SUCCESS = 'SET_DEFAULT_LANGUAGE_SUCCESS'
export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE'
export const SET_CURRENT_LANGUAGE_SUCCESS = 'SET_CURRENT_LANGUAGE_SUCCESS'
export const setLanguages = createAction(
    SET_LANGUAGE,
    props<{ payload: ILanguage[] }>()
)
export const setLanguagesSuccess = createAction(
    SET_LANGUAGE_SUCCESS,
    props<{ payload: ILanguage[] }>()
)
export const setDefaultLanguage = createAction(
    SET_DEFAULT_LANGUAGE,
    props<{ lang: ILanguage }>()
)
export const setDefaultLanguageSuccess = createAction(
    SET_DEFAULT_LANGUAGE_SUCCESS,
    props<{ lang: ILanguage }>()
)
export const setCurrentLanguage = createAction(
    SET_CURRENT_LANGUAGE,
    props<{ lang: ILanguage }>()
)
export const setCurrentLanguageSuccess = createAction(
    SET_CURRENT_LANGUAGE_SUCCESS,
    props<{ lang: ILanguage }>()
)
