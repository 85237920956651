import { Component, Input } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { ISong } from '@app/store/models/song.model'

@Component({
    selector: 'app-song',
    templateUrl: './song.page.html',
    styleUrls: ['./song.page.scss'],
})
export class SongPage {
    @Input() song: {
        payload: ISong
    }

    constructor(public modalCtrl: ModalController) {}

    dismiss() {
        this.modalCtrl.dismiss()
    }
}
