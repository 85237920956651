import { createReducer, on } from '@ngrx/store'
import * as Actions from '@store/actions/proverb.actions'

export default createReducer(
    undefined,
    on(Actions.getFirstProverbSuccess, (state, { payload }) => {
        const proverb = payload[1]

        return {
            ...state,
            currentProverbKey: proverb && proverb.key,
            firstProverbKey: proverb && proverb.key,
            nextProverbKey: proverb && proverb.prevKey,
            proverb: proverb && proverb.payload.val(),
        }
    }),
    on(Actions.getNextProverbSuccess, (state, { payload }) => {
        const proverb = payload[1]

        return {
            ...state,
            currentProverbKey: proverb && proverb.key,
            nextProverbKey: proverb && proverb.prevKey,
            previousProverbKey: state.currentProverbKey,
            proverb: proverb && proverb.payload.val(),
        }
    }),
    on(Actions.getPreviousProverbSuccess, (state, { payload }) => {
        const proverb = payload[0]
        const lastProverb = payload[1]
        const firstIsCurrent = state.firstProverbKey === proverb.key

        return {
            ...state,
            currentProverbKey: proverb && proverb.key,
            nextProverbKey: state.currentProverbKey,
            previousProverbKey:
                lastProverb && !firstIsCurrent && lastProverb.key,
            proverb: proverb && proverb.payload.val(),
        }
    })
)
