import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { TranslateModule } from '@ngx-translate/core'
import { SafeUrlPipe } from '@pipes/safe-url.pipe'
import { HeaderComponent } from './header/header.component'

@NgModule({
    imports: [CommonModule, IonicModule, FormsModule, TranslateModule],
    declarations: [
        // Components
        HeaderComponent,

        // Pipes
        SafeUrlPipe,
    ],
    exports: [
        CommonModule,
        IonicModule,
        FormsModule,
        TranslateModule,

        // Components
        HeaderComponent,

        // Pipes
        SafeUrlPipe,
    ],
})
export class SharedModule {}
