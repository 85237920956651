import { createAction, props } from '@ngrx/store'
import { ITranslation } from '@store/models/translation.model'

export const GET_TRANSLATIONS = 'GET_TRANSLATION'
export const GET_TRANSLATIONS_SUCCESS = 'GET_TRANSLATION_SUCCESS'
export const getTranslations = createAction(GET_TRANSLATIONS)
export const getTranslationsSuccess = createAction(
    GET_TRANSLATIONS_SUCCESS,
    props<{ payload: ITranslation }>()
)
