// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    firebase: {
        apiKey: 'AIzaSyABjCtY0Pdg1qM0IkJcHSfB_SlAccv4RYg',
        authDomain: 'kasoot-52e46.firebaseapp.com',
        databaseURL: 'https://kasoot-52e46.firebaseio.com',
        projectId: 'kasoot-52e46',
        storageBucket: 'kasoot-52e46.appspot.com',
        messagingSenderId: '48814919623',
        appId: '1:48814919623:web:c955900603fe0b908953e2',
    },
    weather: {
        // Test call
        // http://api.openweathermap.org/data/2.5/weather
        // ?appid=8eb9008bc087603350c1528cd6429f2c
        // &lat=29.064229&lon=75.911641
        domain: 'http://api.openweathermap.org/data/2.5/weather',
        apiKey: '8eb9008bc087603350c1528cd6429f2c',
    },
    mandi_prices: {
        // Test call
        // https://api.data.gov.in/resource/9ef84268-d588-465a-a308-a864a43d0070
        // ?api-key=579b464db66ec23bdd000001346e203f29274866504478eef12437af
        // &format=json
        // &limit=1000
        // &offset=0
        // &filters[state]=Haryana
        // Skip these if don't want limits or offset
        domain:
            'https://api.data.gov.in/resource/9ef84268-d588-465a-a308-a864a43d0070',
        apiKey: '579b464db66ec23bdd000001346e203f29274866504478eef12437af',
    },
    youtube: {
        domain: 'https://www.googleapis.com/youtube/v3/search',
        channelId: 'UCvTBpYLoUl0nyoZ5yTeWWnQ',
        apiKey: 'AIzaSyAQqxsr9U3NpjzoXTlNXEU2H2mYOaDwkqE',
    },
    google: {
        domain: 'https://translation.googleapis.com/language/translate/v2',
        apiKey: 'AIzaSyAQqxsr9U3NpjzoXTlNXEU2H2mYOaDwkqE',
    },
    blogger: {
        domain: 'https://www.googleapis.com/blogger/v3/blogs',
        blogId: '1023069622007949673',
        apiKey: 'AIzaSyAQqxsr9U3NpjzoXTlNXEU2H2mYOaDwkqE',
    },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
