import { Action, createAction, props } from '@ngrx/store'
import { IPlayerType } from '@store/models/player-type.model'

export const SET_PLAYER_TYPE = 'SET_PLAYER_TYPE'
export const SET_PLAYER_TYPE_SUCCESS = 'SET_PLAYER_TYPE_SUCCESS'
export const SET_DEFAULT_PLAYER_TYPE = 'SET_DEFAULT_PLAYER_TYPE'
export const SET_CURRENT_PLAYER_TYPE = 'SET_CURRENT_PLAYER_TYPE'
export const SET_DEFAULT_PLAYER_TYPE_SUCCESS = 'SET_DEFAULT_PLAYER_TYPE_SUCCESS'
export const SET_CURRENT_PLAYER_TYPE_SUCCESS = 'SET_CURRENT_PLAYER_TYPE_SUCCESS'
export const setPlayerType = createAction(
    SET_PLAYER_TYPE,
    props<{ payload: IPlayerType[] }>()
)
export const setPlayerTypeSuccess = createAction(
    SET_PLAYER_TYPE_SUCCESS,
    props<{ payload: IPlayerType[] }>()
)
export const setDefaultPlayerType = createAction(
    SET_DEFAULT_PLAYER_TYPE,
    props<{ playerType: IPlayerType }>()
)
export const setDefaultPlayerTypeSuccess = createAction(
    SET_DEFAULT_PLAYER_TYPE_SUCCESS,
    props<{ playerType: IPlayerType }>()
)
export const setCurrentPlayerType = createAction(
    SET_CURRENT_PLAYER_TYPE,
    props<{ playerType: IPlayerType }>()
)
export const setCurrentPlayerTypeSuccess = createAction(
    SET_CURRENT_PLAYER_TYPE_SUCCESS,
    props<{ playerType: IPlayerType }>()
)
