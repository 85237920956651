import { Component } from '@angular/core'
import { ITranslatedValues } from '@app/interfaces'
import { AlertController, ModalController } from '@ionic/angular'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from '@services/auth.service'
import { createWord } from '@store/actions/dictionary.actions'
import { IWord } from '@store/models/dictionary.model'
import { IAppState } from '@store/state'
import { take } from 'rxjs/operators'

@Component({
    selector: 'app-add-word',
    templateUrl: './add-word.page.html',
    styleUrls: ['./add-word.page.scss'],
})
export class AddWordPage {
    public word: IWord

    constructor(
        public modalCtrl: ModalController,
        private alertCtrl: AlertController,
        public authService: AuthService,
        private translateService: TranslateService,
        private store: Store<IAppState>
    ) {}

    ionViewDidEnter() {
        this.store.pipe(take(1)).subscribe((state: IAppState) => {
            this.word = {
                approved: false,
                username: state.auth.displayName,
                name: '',
                hindi: '',
                english: '',
                similar: '',
                example: '',
            }
        })
    }

    saveWord() {
        const hasRequired = !Object.keys(this.word)
            .filter(key => key !== 'approved')
            .some(key => !this.word[key])

        if (hasRequired) {
            this.store.dispatch(createWord({ payload: this.word }))
            this.dismiss()
        } else {
            this.showError()
        }
    }

    dismiss() {
        this.modalCtrl.dismiss()
    }

    showError() {
        const translationKeys: Array<string> = [
            'ALERTS.WORD_ERROR_TITLE',
            'ALERTS.WORD_ERROR_MESSAGE',
            'ALERTS.WORD_ERROR_BUTTON',
        ]

        this.translateService
            .get(translationKeys)
            .subscribe(async (values: ITranslatedValues) => {
                const header: string = values['ALERTS.WORD_ERROR_TITLE'],
                    message: string = values['ALERTS.WORD_ERROR_MESSAGE'],
                    button: string = values['ALERTS.WORD_ERROR_BUTTON']

                const alert = await this.alertCtrl.create({
                    header,
                    message,
                    buttons: [button],
                })

                await alert.present()
            })
    }
}
