import { createAction, props } from '@ngrx/store'
import {
    IWeather,
    IWeatherDescription,
    GeopositionPayload,
} from '@store/models/weather.model'

export const GET_WEATHER = 'GET_WEATHER'
export const GET_WEATHER_SUCCESS = 'GET_WEATHER_SUCCESS'
export const GET_WEATHER_DESCRIPTION = 'GET_WEATHER_DESCRIPTION'
export const GET_WEATHER_DESCRIPTION_SUCCESS = 'GET_WEATHER_DESCRIPTION_SUCCESS'

export const getWeather = createAction(GET_WEATHER, props<GeopositionPayload>())
export const getWeatherSuccess = createAction(
    GET_WEATHER_SUCCESS,
    props<IWeather>()
)
export const getWeatherDescription = createAction(
    GET_WEATHER_DESCRIPTION,
    props<{ code: number }>()
)
export const getWeatherDescriptionSuccess = createAction(
    GET_WEATHER_DESCRIPTION_SUCCESS,
    props<IWeatherDescription>()
)
