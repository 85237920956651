<ion-header>
    <ion-toolbar no-border (click)="vibrate()">
        <div class="logo">
            <img
                src="assets/img/logo.png"
                class="logo-img"
                alt="Radio Kasoot, First Haryanvi Radio"
            />

            <ion-text color="light" class="ion-text-center header-title">
                <strong>{{ 'APP_TITLE' | translate }}</strong>
            </ion-text>

            <ion-icon
                name="information-circle"
                color="light"
                (click)="showInfo()"
            >
            </ion-icon>
        </div>
    </ion-toolbar>

    <ion-toolbar no-border class="subheader">
        <ion-text color="light">{{ 'APP_SUB_TITLE' | translate }}</ion-text>
    </ion-toolbar>
</ion-header>
