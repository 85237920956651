import { createAction, props } from '@ngrx/store'
import { IPoemPayload } from '@store/models/poem.model'

export const GET_POEMS = 'GET_POEMS'
export const GET_POEMS_SUCCESS = 'GET_POEMS_SUCCESS'
export const getPoems = createAction(GET_POEMS, props<{ writerKey: string }>())
export const getPoemsSuccess = createAction(
    GET_POEMS_SUCCESS,
    props<{ payload: IPoemPayload[] }>()
)
