import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

const { domain, channelId, apiKey } = environment.youtube
const maxResults = '6'
const defaultParams = 'part=id,snippet&type=video&order=date'
const youTubeUrl = `${domain}?${defaultParams}&channelId=${channelId}&key=${apiKey}&maxResults=${maxResults}`

@Injectable({ providedIn: 'root' })
export class YoutubeService {
    constructor(public http: HttpClient) {}

    getPosts(pageToken: string) {
        const args = pageToken ? `&pageToken=${pageToken}` : ''
        return this.http.get(youTubeUrl + args)
    }
}
