import { createAction, props } from '@ngrx/store'
import { IAlbumPayload } from '@store/models/album.model'

export const GET_ALBUMS = 'GET_ALBUMS'
export const GET_ALBUMS_SUCCESS = 'GET_ALBUMS_SUCCESS'
export const getAlbums = createAction(
    GET_ALBUMS,
    props<{ writerKey: string }>()
)
export const getAlbumsSuccess = createAction(
    GET_ALBUMS_SUCCESS,
    props<{ payload: IAlbumPayload[] }>()
)
