import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
    getMandiPricesSuccess,
    GET_MANDI_PRICES,
} from '@store/actions/mandi.actions'
import { IMandi } from '@store/models/mandi.model'
import { map, switchMap } from 'rxjs/operators'
import { MandiService } from '@services/mandi.service'

@Injectable()
export class MandiEffects {
    constructor(
        private actions$: Actions,
        private mandiService: MandiService
    ) {}

    getMandiPrice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_MANDI_PRICES),
            switchMap(() => this.mandiService.getMandiPrices()),
            map((payload: IMandi) => getMandiPricesSuccess({ payload }))
        )
    )
}
