import { createAction, props } from '@ngrx/store'
import { IPartner } from '@store/models/partner.model'

export const GET_PARTNERS = 'GET_PARTNERS'
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS'
export const getPartners = createAction(GET_PARTNERS)
export const getPartnersSuccess = createAction(
    GET_PARTNERS_SUCCESS,
    props<{ payload: IPartner[] }>()
)
