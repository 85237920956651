import { createAction, props } from '@ngrx/store'
import { IJoke } from '@store/models/joke.model'
import { ISnapshot } from '@store/state'

export const GET_FIRST_JOKE = 'GET_FIRST_JOKE'
export const GET_FIRST_JOKE_SUCCESS = 'GET_FIRST_JOKE_SUCCESS'
export const GET_NEXT_JOKE = 'GET_NEXT_JOKE'
export const GET_NEXT_JOKE_SUCCESS = 'GET_NEXT_JOKE_SUCCESS'
export const GET_PREVIOUS_JOKE = 'GET_PREVIOUS_JOKE'
export const GET_PREVIOUS_JOKE_SUCCESS = 'GET_PREVIOUS_JOKE_SUCCESS'
export const CREATE_JOKE = 'CREATE_JOKE'
export const CREATE_JOKE_SUCCESS = 'CREATE_JOKE_SUCCESS'
export const getFirstJoke = createAction(GET_FIRST_JOKE)
export const getFirstJokeSuccess = createAction(
    GET_FIRST_JOKE_SUCCESS,
    props<{ payload: ISnapshot[] }>()
)
export const getNextJoke = createAction(GET_NEXT_JOKE, props<{ key: string }>())
export const getNextJokeSuccess = createAction(
    GET_NEXT_JOKE_SUCCESS,
    props<{ payload: ISnapshot[] }>()
)
export const getPreviousJoke = createAction(
    GET_PREVIOUS_JOKE,
    props<{ key: string }>()
)
export const getPreviousJokeSuccess = createAction(
    GET_PREVIOUS_JOKE_SUCCESS,
    props<{ payload: ISnapshot[] }>()
)
export const createJoke = createAction(CREATE_JOKE, props<{ payload: IJoke }>())
export const createJokeSuccess = createAction(CREATE_JOKE_SUCCESS)
