import { Injectable } from '@angular/core'
import { Actions, ofType, createEffect } from '@ngrx/effects'
import {
    setCurrentLanguageSuccess,
    setDefaultLanguageSuccess,
    setLanguagesSuccess,
    SET_CURRENT_LANGUAGE,
    SET_DEFAULT_LANGUAGE,
    SET_LANGUAGE,
} from '@store/actions/language.actions'
import { map } from 'rxjs/operators'

@Injectable()
export class LanguageEffects {
    constructor(private actions$: Actions) {}

    setLanguages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SET_LANGUAGE),
            map(({ payload }) => setLanguagesSuccess({ payload }))
        )
    )

    setDefaultLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SET_DEFAULT_LANGUAGE),
            map(({ lang }) => setDefaultLanguageSuccess({ lang }))
        )
    )

    setCurrentLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SET_CURRENT_LANGUAGE),
            map(({ lang }) => setCurrentLanguageSuccess({ lang }))
        )
    )
}
