import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
    getTranslationsSuccess,
    GET_TRANSLATIONS,
} from '@store/actions/translation.actions'
import { ITranslation } from '@store/models/translation.model'
import { map, mergeMap } from 'rxjs/operators'

@Injectable()
export class TranslationEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {}

    getTranslations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_TRANSLATIONS),
            mergeMap(() => this.database.object('translations').valueChanges()),
            map((payload: ITranslation) => getTranslationsSuccess({ payload }))
        )
    )
}
