import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage'
import { Platform } from '@ionic/angular'

@Injectable({ providedIn: 'root' })
export class StorageService {
    isCordova: boolean

    constructor(private storage: Storage, private platform: Platform) {
        this.isCordova = this.platform.is('cordova')
    }

    async set(location: string, data: object) {
        this.isCordova
            ? await this.storage.set(location, data)
            : localStorage.setItem(location, JSON.stringify(data))
    }

    async get(location: string) {
        return this.isCordova
            ? await this.storage.get(location)
            : JSON.parse(localStorage.getItem(location))
    }
}
