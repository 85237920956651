import { Injectable } from '@angular/core'
import { AlertController } from '@ionic/angular'

// translate service
import { TranslateService } from '@ngx-translate/core'

// Ionic Native
import { Shake } from '@ionic-native/shake/ngx'

@Injectable({ providedIn: 'root' })
export class ShakeService {
    public shakeMessages: Array<object>
    public shakeCount = -1
    public shakeAlert: HTMLIonAlertElement

    constructor(
        private alertCtrl: AlertController,
        private shake: Shake,
        private translateService: TranslateService
    ) {}

    // populate shake messages
    populateShakeMessages() {
        const translationKeys: Array<string> = [
            'ALERTS.SHAKE_MESSAGE_ONE_TITLE',
            'ALERTS.SHAKE_MESSAGE_ONE_MESSAGE',
            'ALERTS.SHAKE_MESSAGE_TWO_TITLE',
            'ALERTS.SHAKE_MESSAGE_TWO_MESSAGE',
            'ALERTS.SHAKE_MESSAGE_THREE_TITLE',
            'ALERTS.SHAKE_MESSAGE_THREE_MESSAGE',
        ]

        this.translateService
            .get(translationKeys)
            .subscribe(async (values: object) => {
                const oneTitle: string =
                        values['ALERTS.SHAKE_MESSAGE_ONE_TITLE'],
                    oneMessage: string =
                        values['ALERTS.SHAKE_MESSAGE_ONE_MESSAGE'],
                    twoTitle: string = values['ALERTS.SHAKE_MESSAGE_TWO_TITLE'],
                    twoMessage: string =
                        values['ALERTS.SHAKE_MESSAGE_TWO_MESSAGE'],
                    threeTitle: string =
                        values['ALERTS.SHAKE_MESSAGE_THREE_TITLE'],
                    threeMessage: string =
                        values['ALERTS.SHAKE_MESSAGE_THREE_MESSAGE']

                this.shakeMessages = [
                    { title: oneTitle, message: oneMessage },
                    { title: twoTitle, message: twoMessage },
                    { title: threeTitle, message: threeMessage },
                ]
            })
    }

    getShakeCount(): number {
        this.shakeCount++
        if (this.shakeCount >= this.shakeMessages.length) {
            this.shakeCount = 0
        }

        return this.shakeCount
    }

    // watch shake gesture
    watchShake() {
        this.shake.startWatch(50).subscribe(async () => {
            this.populateShakeMessages()
            let okMessage: string
            this.translateService
                .get('ALERTS.SHAKE_MESSAGE_OK_BUTTON')
                .subscribe(value => (okMessage = value))

            if (!!this.shakeAlert) {
                this.shakeAlert.dismiss()
            }

            const message = this.shakeMessages[this.getShakeCount()]

            this.shakeAlert = await this.alertCtrl.create({
                header: message['title'],
                message: message['message'],
                buttons: [okMessage],
            })

            await this.shakeAlert.present()
        })
    }
}
