<ion-header>
    <ion-toolbar color="kesariya">
        <ion-buttons slot="start">
            <ion-button color="dhola" (click)="dismiss()">
                <ion-icon name="close-circle" size="large"></ion-icon>
            </ion-button>
        </ion-buttons>

        <ion-title>{{ 'REQUESTS.NEW_REQUEST' | translate }}</ion-title>

        <ion-buttons slot="end">
            <ion-button color="dhola" (click)="dismiss()">
                {{ 'DICTIONARY.CANCEL' | translate }}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div *ngIf="request">
        <ion-item>
            <ion-label position="floating"
                >{{ 'REQUESTS.ADD_REQUEST_TITLE' | translate }}</ion-label
            >
            <ion-input name="title" [(ngModel)]="request.title"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating"
                >{{ 'REQUESTS.ADD_REQUESTER_NAME' | translate }}</ion-label
            >
            <ion-input name="name" [(ngModel)]="request.name"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating"
                >{{ 'REQUESTS.ADD_REQUESTER_ADDRESS' | translate }}</ion-label
            >
            <ion-input name="address" [(ngModel)]="request.address"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating"
                >{{ 'REQUESTS.ADD_REQUESTER_MESSAGE' | translate }}</ion-label
            >
            <ion-textarea
                rows="3"
                name="message"
                [attr.maxlength]="100"
                [(ngModel)]="request.message"
            >
            </ion-textarea>
        </ion-item>

        <ion-item>
            <ion-label position="floating"
                >{{ 'REQUESTS.ADD_REQUESTER_CONTACT' | translate }}</ion-label
            >
            <ion-input name="contact" [(ngModel)]="request.contact"></ion-input>
        </ion-item>
    </div>

    <ion-fab vertical="bottom" horizontal="end">
        <ion-fab-button color="kesariya" (click)="saveRequest()">
            <ion-icon name="send"></ion-icon>
        </ion-fab-button>
    </ion-fab>
</ion-content>
