import { InjectionToken } from '@angular/core'
import { ActionReducerMap } from '@ngrx/store'
import { IAppState } from '../state'
import albums from './album.reducer'
import auth from './auth.reducer'
import blog from './blog.reducer'
import dictionary from './dictionary.reducer'
import jokes from './joke.reducer'
import languages from './language.reducer'
import loader from './loader.reducer'
import mandi from './mandi.reducer'
import partners from './partner.reducer'
import playerTypes from './player-type.reducer'
import player from './player.reducer'
import poems from './poem.reducer'
import programs from './program.reducer'
import proverbs from './proverb.reducer'
import requests from './request.reducer'
import requestsAreTaken from './requests-are-taken.reducer'
import songs from './song.reducer'
import team from './team.reducer'
import translations from './translation.reducer'
import weather from './weather.reducer'
import writers from './writer.reducer'
import youtube from './youtube.reducer'

export const rootReducer = {
    albums,
    auth,
    blog,
    dictionary,
    jokes,
    languages,
    loader,
    mandi,
    partners,
    playerTypes,
    player,
    poems,
    programs,
    proverbs,
    requests,
    requestsAreTaken,
    songs,
    team,
    translations,
    weather,
    writers,
    youtube,
}

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<IAppState>>(
    'Registered Reducers'
)
