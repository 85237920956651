import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
    createProverbSuccess,
    CREATE_PROVERB,
    getFirstProverbSuccess,
    getNextProverbSuccess,
    getPreviousProverbSuccess,
    GET_FIRST_PROVERB,
    GET_NEXT_PROVERB,
    GET_PREVIOUS_PROVERB,
} from '@store/actions/proverb.actions'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class ProverbEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {}

    getFirstProverb$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_FIRST_PROVERB),
            switchMap(() =>
                this.database
                    .list('proverbs', ref =>
                        ref
                            .orderByChild('approved')
                            .equalTo(true)
                            .limitToLast(2)
                    )
                    .snapshotChanges()
            ),
            map(payload => getFirstProverbSuccess({ payload }))
        )
    )

    getNextProverb$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_NEXT_PROVERB),
            switchMap(({ key }) =>
                this.database
                    .list('proverbs', ref =>
                        ref
                            .orderByKey()
                            .endAt(key)
                            .limitToLast(2)
                    )
                    .snapshotChanges()
            ),
            map(payload => getNextProverbSuccess({ payload }))
        )
    )

    getPreviousProverb$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_PREVIOUS_PROVERB),
            switchMap(({ key }) =>
                this.database
                    .list('proverbs', ref =>
                        ref
                            .orderByKey()
                            .startAt(key)
                            .limitToFirst(2)
                    )
                    .snapshotChanges()
            ),
            map(payload => getPreviousProverbSuccess({ payload }))
        )
    )

    createProverb$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CREATE_PROVERB),
            switchMap(({ payload }) =>
                this.database.list('proverbs').push(payload)
            ),
            map(() => createProverbSuccess())
        )
    )
}
