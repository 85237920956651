import { createReducer, on } from '@ngrx/store'
import * as Actions from '@store/actions/dictionary.actions'

export default createReducer(
    undefined,
    on(Actions.getFirstWordSuccess, (state, { payload }) => {
        const word = payload[1]

        return {
            ...state,
            firstWordKey: word && word.key,
            currentWordKey: word && word.key,
            nextWordKey: word && word.prevKey,
            word: word && word.payload.val(),
        }
    }),
    on(Actions.getNextWordSuccess, (state, { payload }) => {
        const word = payload[1]

        return {
            ...state,
            currentWordKey: word && word.key,
            nextWordKey: word && word.prevKey,
            previousWordKey: state.currentWordKey,
            word: word && word.payload.val(),
        }
    }),
    on(Actions.getPreviousWordSuccess, (state, { payload }) => {
        const word = payload[0]
        const lastWord = payload[1]
        const firstIsCurrent = state.firstWordKey === word.key

        return {
            ...state,
            currentWordKey: word && word.key,
            nextWordKey: state.currentWordKey,
            previousWordKey: lastWord && !firstIsCurrent && lastWord.key,
            word: word && word.payload.val(),
        }
    })
)
