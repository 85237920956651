<ion-header>
    <ion-toolbar color="kesariya">
        <ion-buttons slot="start">
            <ion-button (click)="dismiss()">
                <ion-icon name="close-circle" size="large"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>Poori Kavita</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content padding>
    <p class="animated slideInDown delay-100ms">{{ poem.payload.title }}</p>
    <p
        class="animated slideInUp delay-100ms"
        [innerHTML]="poem.payload.content"
    ></p>
</ion-content>
