import { Injectable } from '@angular/core'
import { LoadingController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { Store } from '@ngrx/store'
import { IAppState } from '@store/state'
import { ILoader } from '@store/models/loader.model'

@Injectable({ providedIn: 'root' })
export class LoadingService {
    private loading: HTMLIonLoadingElement

    constructor(
        private loadingCtrl: LoadingController,
        private translateService: TranslateService,
        private store: Store<IAppState>
    ) {}

    init() {
        this.store
            .select('loader')
            .subscribe((loader: ILoader) =>
                loader && loader.isShowing
                    ? this.presentLoading()
                    : this.dismissLoading()
            )
    }

    private presentLoading() {
        this.translateService
            .get('ALERTS.LOADING_TEXT')
            .subscribe(async (message: string) => {
                message =
                    message === 'ALERTS.LOADING_TEXT'
                        ? 'Loading Kasoot...'
                        : message
                this.loading = await this.loadingCtrl.create({
                    message,
                    duration: 5000,
                })

                this.loading.present()
            })
    }

    private dismissLoading() {
        if (this.loading) {
            this.loading.dismiss()
        }
    }
}
