<ion-header>
    <ion-toolbar color="kesariya">
        <ion-buttons slot="start">
            <ion-button color="dhola" (click)="dismiss()">
                <ion-icon name="close-circle" size="large"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{ 'DICTIONARY.TITLE' | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button color="dhola" (click)="dismiss()">
                {{ 'DICTIONARY.CANCEL' | translate }}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ng-container *ngIf="word">
        <ion-item>
            <ion-label position="floating"
                >{{ 'DICTIONARY.WORD' | translate }}</ion-label
            >
            <ion-input name="name" [(ngModel)]="word.name"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating"
                >{{ 'DICTIONARY.HINDI_MEANING' | translate }}</ion-label
            >
            <ion-input name="hindi" [(ngModel)]="word.hindi"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating"
                >{{ 'DICTIONARY.ENGLISH_MEANING' | translate }}</ion-label
            >
            <ion-input name="english" [(ngModel)]="word.english"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating"
                >{{ 'DICTIONARY.SIMILAR_WORDS' | translate }}</ion-label
            >
            <ion-input name="similar" [(ngModel)]="word.similar"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating"
                >{{ 'DICTIONARY.EXAMPLE' | translate }}</ion-label
            >
            <ion-textarea
                rows="5"
                [attr.maxlength]="200"
                name="example"
                [(ngModel)]="word.example"
            ></ion-textarea>
        </ion-item>

        <ion-item>
            <ion-label position="floating"
                >{{ 'DICTIONARY.USERNAME' | translate }}</ion-label
            >
            <ion-input
                name="username"
                disabled="true"
                [(ngModel)]="word.username"
            ></ion-input>
        </ion-item>

        <ion-fab vertical="bottom" horizontal="end" slot="fixed">
            <ion-fab-button (click)="saveWord()">
                <ion-icon name="send"></ion-icon>
            </ion-fab-button>
        </ion-fab>
    </ng-container>
</ion-content>
