import { Component } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { ITranslatedValues } from '@app/interfaces'
import { AppVersion } from '@ionic-native/app-version/ngx'
import { Vibration } from '@ionic-native/vibration/ngx'
import { AlertController, Platform } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { GlobalService } from '@services/global.service'
import { upperFirst } from 'lodash'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent {
    constructor(
        private vibration: Vibration,
        private alertCtrl: AlertController,
        private appVersion: AppVersion,
        private platform: Platform,
        private translateService: TranslateService,
        private router: Router,
        private globalService: GlobalService
    ) {
        this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                const splittedPath = e.url.split('/')
                const pageName = upperFirst(
                    splittedPath[splittedPath.length - 1]
                )
                this.globalService.setTitle(pageName)
            }
        })
    }

    vibrate(time = 300): void {
        this.vibration.vibrate(time)
    }

    showInfo() {
        // show alert without
        if (!this.platform.is('cordova')) {
            return this.info(null)
        }

        // get version app from app
        this.appVersion.getVersionNumber().then(version => this.info(version))
    }

    info(appVersion: string) {
        const translationKeys: Array<string> = [
            'ALERTS.INFO_TITLE',
            'ALERTS.INFO_SUB_TITLE',
            'ALERTS.INFO_MESSAGE',
            'ALERTS.INFO_OK',
        ]

        this.translateService
            .get(translationKeys)
            .subscribe(async (values: ITranslatedValues) => {
                let header: string = values['ALERTS.INFO_TITLE']

                const subHeader: string = values['ALERTS.INFO_SUB_TITLE'],
                    message: string = values['ALERTS.INFO_MESSAGE'],
                    button: string = values['ALERTS.INFO_OK']

                if (appVersion) {
                    header += appVersion
                }

                const alert = await this.alertCtrl.create({
                    header,
                    subHeader,
                    message,
                    buttons: [button],
                })

                await alert.present()
            })
    }
}
