import { Injectable } from '@angular/core'
import { Actions, ofType, createEffect } from '@ngrx/effects'
import { GET_ALBUMS, GET_ALBUMS_SUCCESS } from '@store/actions/album.actions'
import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGOUT,
    LOGOUT_SUCCESS,
} from '@store/actions/auth.actions'
import { hideLoader, showLoader } from '@store/actions/loader.actions'
import {
    GET_WEATHER,
    GET_WEATHER_SUCCESS,
} from '@store/actions/weather.actions'
import { map } from 'rxjs/operators'
import {
    GET_BLOG_POST,
    GET_BLOG_POST_SUCCESS,
} from '@store/actions/blog.actions'
import {
    CREATE_WORD,
    CREATE_WORD_SUCCESS,
    GET_FIRST_WORD,
    GET_FIRST_WORD_SUCCESS,
    GET_PREVIOUS_WORD,
    GET_PREVIOUS_WORD_SUCCESS,
} from '@store/actions/dictionary.actions'
import {
    CREATE_JOKE,
    CREATE_JOKE_SUCCESS,
    GET_FIRST_JOKE,
    GET_FIRST_JOKE_SUCCESS,
    GET_NEXT_JOKE,
    GET_NEXT_JOKE_SUCCESS,
    GET_PREVIOUS_JOKE,
    GET_PREVIOUS_JOKE_SUCCESS,
} from '@store/actions/joke.actions'
import {
    SET_DEFAULT_LANGUAGE,
    SET_DEFAULT_LANGUAGE_SUCCESS,
    SET_LANGUAGE,
    SET_LANGUAGE_SUCCESS,
} from '@store/actions/language.actions'
import {
    GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
} from '@store/actions/partner.actions'
import { GET_POEMS, GET_POEMS_SUCCESS } from '@store/actions/poem.actions'
import {
    GET_PROGRAM,
    GET_PROGRAM_SUCCESS,
} from '@store/actions/program.actions'
import {
    GET_REQUESTS,
    GET_REQUESTS_SUCCESS,
} from '@store/actions/request.actions'
import {
    GET_REQUESTS_ARE_TAKEN,
    GET_REQUESTS_ARE_TAKEN_SUCCESS,
} from '@store/actions/requests-are-taken.actions'
import { GET_SONGS, GET_SONGS_SUCCESS } from '@store/actions/song.actions'
import { GET_TEAM, GET_TEAM_SUCCESS } from '@store/actions/team.actions'
import {
    GET_TRANSLATIONS,
    GET_TRANSLATIONS_SUCCESS,
} from '@store/actions/translation.actions'
import { GET_WRITERS, GET_WRITERS_SUCCESS } from '@store/actions/writer.actions'
import {
    GET_YOU_TUBE,
    GET_YOU_TUBE_SUCCESS,
} from '@store/actions/youtube.actions'

const startLoadingActions = [
    GET_ALBUMS,
    LOGIN,
    LOGOUT,
    GET_BLOG_POST,
    GET_FIRST_WORD,
    GET_NEXT_JOKE,
    GET_PREVIOUS_WORD,
    CREATE_WORD,
    GET_FIRST_JOKE,
    GET_NEXT_JOKE,
    GET_PREVIOUS_JOKE,
    CREATE_JOKE,
    SET_LANGUAGE,
    SET_DEFAULT_LANGUAGE,
    GET_PARTNERS,
    GET_POEMS,
    GET_PROGRAM,
    GET_REQUESTS,
    GET_REQUESTS_ARE_TAKEN,
    GET_SONGS,
    GET_TEAM,
    GET_TRANSLATIONS,
    GET_WEATHER,
    GET_WRITERS,
    GET_YOU_TUBE,
]

const stopLoadingActions = [
    GET_ALBUMS_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    GET_BLOG_POST_SUCCESS,
    GET_FIRST_WORD_SUCCESS,
    GET_NEXT_JOKE_SUCCESS,
    GET_PREVIOUS_WORD_SUCCESS,
    CREATE_WORD_SUCCESS,
    GET_FIRST_JOKE_SUCCESS,
    GET_NEXT_JOKE_SUCCESS,
    GET_PREVIOUS_JOKE_SUCCESS,
    CREATE_JOKE_SUCCESS,
    SET_LANGUAGE_SUCCESS,
    SET_DEFAULT_LANGUAGE_SUCCESS,
    GET_PARTNERS_SUCCESS,
    GET_POEMS_SUCCESS,
    GET_PROGRAM_SUCCESS,
    GET_REQUESTS_SUCCESS,
    GET_REQUESTS_ARE_TAKEN_SUCCESS,
    GET_SONGS_SUCCESS,
    GET_TEAM_SUCCESS,
    GET_TRANSLATIONS_SUCCESS,
    GET_WEATHER_SUCCESS,
    GET_WRITERS_SUCCESS,
    GET_YOU_TUBE_SUCCESS,
]

@Injectable()
export class LoaderEffects {
    constructor(private actions$: Actions) {}

    showLoader$ = createEffect(() =>
        this.actions$.pipe(
            ofType(...startLoadingActions),
            map(showLoader)
        )
    )

    hideLoader$ = createEffect(() =>
        this.actions$.pipe(
            ofType(...stopLoadingActions),
            map(hideLoader)
        )
    )
}
