import { createReducer, on } from '@ngrx/store'
import * as Actions from '@store/actions/weather.actions'

export default createReducer(
    undefined,
    on(Actions.getWeatherSuccess, (state, payload) => ({
        ...state,
        data: payload,
        info: undefined,
    })),
    on(Actions.getWeatherDescriptionSuccess, (state, payload) => ({
        ...state,
        info: payload,
    }))
)
