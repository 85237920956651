<ion-header>
    <ion-toolbar color="kesariya">
        <ion-buttons slot="start">
            <ion-button color="dhola" (click)="dismiss()">
                <ion-icon name="close-circle" size="large"></ion-icon>
            </ion-button>
        </ion-buttons>

        <ion-title>{{ 'JOKE.NEW' | translate }}</ion-title>

        <ion-buttons slot="end">
            <ion-button color="dhola" (click)="dismiss()">
                {{ 'JOKE.CANCEL' | translate }}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ng-container *ngIf="joke">
        <!-- <ejs-richtexteditor
            [(ngModel)]="joke.title"
            [toolbarSettings]="editorConfig"
            [placeholder]="'JOKE.NEW' | translate"
        ></ejs-richtexteditor> -->

        <ion-textarea [(ngModel)]="joke.title"></ion-textarea>

        <ion-item>
            <ion-label position="floating"
                >{{ 'JOKE.USERNAME' | translate }}</ion-label
            >
            <ion-input
                name="username"
                disabled="true"
                [(ngModel)]="joke.username"
            ></ion-input>
        </ion-item>

        <ion-fab verticle="bottom" horizontal="end">
            <ion-fab-button (click)="saveJoke()">
                <ion-icon name="send"></ion-icon>
            </ion-fab-button>
        </ion-fab>
    </ng-container>
</ion-content>
