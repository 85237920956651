import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { getTeamSuccess, GET_TEAM } from '@store/actions/team.actions'
import { ITeam } from '@store/models/team.model'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class TeamEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {}

    getTeam$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_TEAM),
            switchMap(() => this.database.list('team').valueChanges()),
            map((payload: ITeam[]) => getTeamSuccess({ payload }))
        )
    )
}
