import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { getAlbumsSuccess, GET_ALBUMS } from '@store/actions/album.actions'
import { IAlbumPayload } from '@store/models/album.model'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class AlbumEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {}

    getAlbums$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_ALBUMS),
            switchMap(({ writerKey }) =>
                this.database
                    .list('/albums', ref =>
                        ref.orderByChild('writerKey').equalTo(writerKey)
                    )
                    .snapshotChanges()
                    .pipe(
                        map(snapshot =>
                            snapshot.map(album => ({
                                key: album.payload.key,
                                payload: album.payload.val(),
                            }))
                        )
                    )
            ),
            map((payload: IAlbumPayload[]) => getAlbumsSuccess({ payload }))
        )
    )
}
