import { createAction, props } from '@ngrx/store'
import { IRequest } from '@store/models/request.model'

export const GET_REQUESTS = 'GET_REQUEST'
export const GET_REQUESTS_SUCCESS = 'GET_REQUEST_SUCCESS'
export const CREATE_REQUEST = 'CREATE_REQUEST'
export const getRequests = createAction(GET_REQUESTS)
export const getRequestsSuccess = createAction(
    GET_REQUESTS_SUCCESS,
    props<{ payload: IRequest[] }>()
)
export const createRequest = createAction(
    CREATE_REQUEST,
    props<{ payload: IRequest }>()
)
