<ion-header>
    <ion-toolbar color="kesariya">
        <ion-buttons slot="start">
            <ion-button (click)="dismiss()">
                <ion-icon name="close-circle" size="large"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list>
        <ion-item
            *ngFor="let record of records; let i = index"
            class="animated flipInX delay-{{(1+i)*10}}ms"
        >
            {{ record?.commodity }}
            <ion-note slot="end">₹ {{ record?.modal_price }}</ion-note>
        </ion-item>
    </ion-list>
</ion-content>
