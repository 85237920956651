import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
    createWordSuccess,
    CREATE_WORD,
    getFirstWordSuccess,
    getPreviousWordSuccess,
    GET_FIRST_WORD,
    GET_NEXT_WORD,
    GET_PREVIOUS_WORD,
    getNextWordSuccess,
} from '@store/actions/dictionary.actions'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class DictionaryEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {}

    getFirstWord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_FIRST_WORD),
            switchMap(() =>
                this.database
                    .list('dictionary', ref =>
                        ref
                            .orderByChild('approved')
                            .equalTo(true)
                            .limitToLast(2)
                    )
                    .snapshotChanges()
            ),
            map(payload => getFirstWordSuccess({ payload }))
        )
    )

    getNextWord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_NEXT_WORD),
            switchMap(({ key }) =>
                this.database
                    .list('dictionary', ref =>
                        ref
                            .orderByKey()
                            .endAt(key)
                            .limitToLast(2)
                    )
                    .snapshotChanges()
            ),
            map(payload => getNextWordSuccess({ payload }))
        )
    )

    getPreviousWord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_PREVIOUS_WORD),
            switchMap(({ key }) =>
                this.database
                    .list('dictionary', ref =>
                        ref
                            .orderByKey()
                            .startAt(key)
                            .limitToFirst(2)
                    )
                    .snapshotChanges()
            ),
            map(payload => getPreviousWordSuccess({ payload }))
        )
    )

    createWord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CREATE_WORD),
            switchMap(({ payload }) =>
                this.database.list('dictionary').push(payload)
            ),
            map(() => createWordSuccess())
        )
    )
}
