import { Component } from '@angular/core'
import { ITranslatedValues } from '@app/interfaces'
import { AlertController, ModalController } from '@ionic/angular'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from '@services/auth.service'
import { createProverb } from '@store/actions/proverb.actions'
import { IProverb } from '@store/models/proverb.model'
import { IAppState } from '@store/state'
import { take } from 'rxjs/operators'

@Component({
    selector: 'app-add-proverb',
    templateUrl: './add-proverb.page.html',
    styleUrls: ['./add-proverb.page.scss'],
})
export class AddProverbPage {
    public proverb: IProverb
    public editorConfig: object

    constructor(
        public modalCtrl: ModalController,
        private alertCtrl: AlertController,
        public authService: AuthService,
        private translateService: TranslateService,
        private store: Store<IAppState>
    ) {}

    ionViewDidEnter() {
        this.editorConfig = {
            items: [
                'Bold',
                'Italic',
                'Underline',
                '-',
                'ClearFormat',
                '-',
                'OrderedList',
                'UnorderedList',
                '-',
                'Formats',
                '-',
            ],
        }

        this.store.pipe(take(1)).subscribe((state: IAppState) => {
            this.proverb = {
                approved: false,
                username: state.auth.displayName,
                title: '',
                meaning: '',
            }
        })
    }

    saveProverb() {
        if (this.proverb.title && this.proverb.meaning) {
            this.store.dispatch(createProverb({ payload: this.proverb }))
            this.dismiss()
        } else {
            this.showError()
        }
    }

    dismiss() {
        this.modalCtrl.dismiss()
    }

    showError() {
        const translationKeys: Array<string> = [
            'ALERTS.PROVERB_ERROR_TITLE',
            'ALERTS.PROVERB_ERROR_MESSAGE',
            'ALERTS.PROVERB_ERROR_BUTTON',
        ]

        this.translateService
            .get(translationKeys)
            .subscribe(async (values: ITranslatedValues) => {
                const header = values['ALERTS.PROVERB_ERROR_TITLE'],
                    message = values['ALERTS.PROVERB_ERROR_MESSAGE'],
                    button = values['ALERTS.PROVERB_ERROR_BUTTON']

                const alert = await this.alertCtrl.create({
                    header,
                    message,
                    buttons: [button],
                })

                await alert.present()
            })
    }
}
