import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
    getRequestsAreTakenSuccess,
    GET_REQUESTS_ARE_TAKEN,
} from '@store/actions/requests-are-taken.actions'
import { IRequestsAreTaken } from '@store/models/requests-are-taken.model'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class RequestsAreTakenEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {}

    getRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_REQUESTS_ARE_TAKEN),
            switchMap(() =>
                this.database.object('requestsAreTaken').valueChanges()
            ),
            map((payload: IRequestsAreTaken) =>
                getRequestsAreTakenSuccess({ payload })
            )
        )
    )
}
