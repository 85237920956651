import { createAction, props } from '@ngrx/store'
import { IMandi } from '@store/models/mandi.model'

export const GET_MANDI_PRICES = 'GET_MANDI_PRICES'
export const GET_MANDI_PRICES_SUCCESS = 'GET_MANDI_PRICES_SUCCESS'
export const getMandiPrices = createAction(GET_MANDI_PRICES)
export const getMandiPricesSuccess = createAction(
    GET_MANDI_PRICES_SUCCESS,
    props<{ payload: IMandi }>()
)
