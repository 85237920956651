import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { AuthService } from '@services/auth.service'
import {
    LOGIN,
    loginSuccess,
    LOGOUT,
    logoutSuccess,
} from '@store/actions/auth.actions'
import { of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class AuthEffects {
    constructor(private actions$: Actions, private authService: AuthService) {}

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LOGIN),
            switchMap(async () => await this.authService.facebookLogin()),
            map(payload =>
                !payload ? logoutSuccess() : loginSuccess({ payload })
            )
        )
    )

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LOGOUT),
            switchMap(async () => of(await this.authService.logout())),
            map(() => logoutSuccess())
        )
    )
}
