import { Injectable } from '@angular/core'
import { AlertController, Platform } from '@ionic/angular'
import { AlertInput } from '@ionic/core'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { ITranslatedValues } from '@app/interfaces'
import {
    setDefaultPlayerType,
    setPlayerType,
    setCurrentPlayerType,
} from '@store/actions/player-type.actions'
import { IPlayerType } from '@store/models/player-type.model'
import { IAppState } from '@store/state'
import { StorageService } from './storage.service'

const currentPlayerTypePath = 'currentPlayerType'

@Injectable({ providedIn: 'root' })
export class PlayerTypeService {
    currentPlayerType: IPlayerType
    defaultPlayerType: IPlayerType
    playerTypes: any[]

    constructor(
        private alertCtrl: AlertController,
        private platform: Platform,
        private storage: StorageService,
        private store: Store<IAppState>,
        private translateService: TranslateService
    ) {
        this.playerTypes = [
            { value: 'web', label: 'Web Player' },
            { value: 'native', label: 'Native Player' },
        ]

        this.defaultPlayerType = this.playerTypes.find(
            (playerType: IPlayerType) =>
                playerType.value ===
                (this.platform.is('cordova') ? 'native' : 'web')
        )

        // Set default player
        this.setPlayerType(this.defaultPlayerType)

        this.store.dispatch(setPlayerType({ payload: this.playerTypes }))
        this.store.dispatch(
            setDefaultPlayerType({ playerType: this.defaultPlayerType })
        )
        this.store.dispatch(
            setCurrentPlayerType({ playerType: this.defaultPlayerType })
        )
    }

    async setPlayerType(playerType: IPlayerType): Promise<void> {
        await this.storage.set(currentPlayerTypePath, playerType)
        this.setCurrentPlayerType()
    }

    async setCurrentPlayerType(): Promise<void> {
        // get current playerType
        this.currentPlayerType = await this.storage.get(currentPlayerTypePath)

        if (this.currentPlayerType) {
            this.store.dispatch(
                setCurrentPlayerType({ playerType: this.currentPlayerType })
            )
        }
    }

    playerTypePopup(): void {
        const translationKeys: Array<string> = [
            'PLAYER',
            'ALERTS.LANGUAGE_CHANGE',
            'ALERTS.LANGUAGE_DONT_CHANGE',
        ]

        this.translateService
            .get(translationKeys)
            .subscribe(async (values: ITranslatedValues) => {
                const header = values['PLAYER'],
                    yesChange = values['ALERTS.LANGUAGE_CHANGE'],
                    dontChange = values['ALERTS.LANGUAGE_DONT_CHANGE']

                const alert = await this.alertCtrl.create({
                    header,
                    inputs: this.playerTypes.map(
                        (playerType: IPlayerType) =>
                            ({
                                type: 'radio',
                                label: playerType.label,
                                value: playerType,
                                checked:
                                    this.currentPlayerType &&
                                    this.currentPlayerType.value ===
                                        playerType.value,
                            } as AlertInput)
                    ),
                    buttons: [
                        { text: dontChange },
                        {
                            text: yesChange,
                            handler: (playerType: IPlayerType) =>
                                this.setPlayerType(playerType),
                        },
                    ],
                })

                await alert.present()
            })
    }
}
