import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'

const { domain, blogId, apiKey } = environment.blogger

@Injectable({ providedIn: 'root' })
export class BlogService {
    private blogUrl = `${domain}/${blogId}/posts?key=${apiKey}`

    constructor(public http: HttpClient) {}

    getNextPost(pageToken: string) {
        let args =
            '&maxResults=1&status=live&view=READER&fields=items(content,customMetaData,title),nextPageToken'
        args += pageToken ? `&pageToken=${pageToken}` : ''

        return this.http.get(this.blogUrl + args)
    }
}
