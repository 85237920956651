import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { getYoutubeSuccess, GET_YOU_TUBE } from '@store/actions/youtube.actions'
import { IYoutube } from '@store/models/youtube.model'
import { map, switchMap } from 'rxjs/operators'
import { YoutubeService } from '@services/youtube.service'

@Injectable()
export class YoutubeEffects {
    constructor(
        private actions$: Actions,
        private youtubeService: YoutubeService
    ) {}

    getYoutube$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_YOU_TUBE),
            switchMap(({ payload }) => this.youtubeService.getPosts(payload)),
            map((payload: IYoutube) => getYoutubeSuccess({ payload }))
        )
    )
}
