import { createAction, props } from '@ngrx/store'
import { IRequestsAreTaken } from '@store/models/requests-are-taken.model'

export const GET_REQUESTS_ARE_TAKEN = 'GET_REQUESTS_ARE_TAKEN'
export const GET_REQUESTS_ARE_TAKEN_SUCCESS = 'GET_REQUESTS_ARE_TAKEN_SUCCESS'
export const getRequestsAreTaken = createAction(GET_REQUESTS_ARE_TAKEN)
export const getRequestsAreTakenSuccess = createAction(
    GET_REQUESTS_ARE_TAKEN_SUCCESS,
    props<{ payload: IRequestsAreTaken }>()
)
