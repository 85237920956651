import { createAction, props } from '@ngrx/store'
import { IYoutube } from '@store/models/youtube.model'

export const GET_YOU_TUBE = 'GET_YOU_TUBE'
export const GET_YOU_TUBE_SUCCESS = 'GET_YOU_TUBE_SUCCESS'
export const getYoutube = createAction(
    GET_YOU_TUBE,
    props<{ payload: string }>()
)
export const getYoutubeSuccess = createAction(
    GET_YOU_TUBE_SUCCESS,
    props<{ payload: IYoutube }>()
)
