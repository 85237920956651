import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { getProgramSuccess, GET_PROGRAM } from '@store/actions/program.actions'
import { IProgram } from '@store/models/program.model'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class ProgramEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {}

    getPrograms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_PROGRAM),
            switchMap(() => this.database.list('programs').valueChanges()),
            map((payload: IProgram[]) => getProgramSuccess({ payload }))
        )
    )
}
