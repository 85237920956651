import { createAction, props } from '@ngrx/store'
import { IAuth } from '@store/models/auth.model'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const login = createAction(LOGIN)
export const loginSuccess = createAction(
    LOGIN_SUCCESS,
    props<{ payload: IAuth }>()
)
export const logout = createAction(LOGOUT)
export const logoutSuccess = createAction(LOGOUT_SUCCESS)
