import { createReducer, on } from '@ngrx/store'
import * as Actions from '@store/actions/requests-are-taken.actions'

export default createReducer(
    undefined,
    on(Actions.getRequestsAreTakenSuccess, (state, { payload }) => ({
        ...state,
        ...payload,
    }))
)
