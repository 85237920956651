<ion-app *ngIf="isLoaded; else loading">
    <ion-router-outlet></ion-router-outlet>
</ion-app>

<ng-template #loading>
    <ion-header>
        <ion-toolbar no-border>
            <div class="logo">
                <img
                    src="assets/img/logo.png"
                    class="logo-img"
                    alt="Radio Kasoot, First Haryanvi Radio"
                />

                <ion-text
                    class="ion-text-center"
                    style="width: 30%; margin: 0 auto"
                >
                    <ion-skeleton-text animated></ion-skeleton-text>
                </ion-text>

                <ion-icon name="information-circle" color="light"> </ion-icon>
            </div>
        </ion-toolbar>

        <ion-toolbar no-border class="subheader">
            <ion-skeleton-text
                animated
                style="width: 60%; margin: 0 auto"
            ></ion-skeleton-text>
        </ion-toolbar>
    </ion-header>

    <ion-grid>
        <ion-row>
            <ion-col class="ion-text-center" *ngFor="let i of [1, 2, 3]">
                <ion-button color="light" fill="outline" size="small">
                    <ion-skeleton-text
                        animated
                        style="width: 30px;"
                    ></ion-skeleton-text>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>

    <div class="ion-padding ion-text-center large-middle-icon">
        <ion-icon name="play-circle" color="light"></ion-icon>
    </div>

    <div class="grid-content">
        <ion-grid class="bottom-grid">
            <ion-row>
                <ion-col size="6" class="ion-text-center">
                    <div class="col-content">
                        <ion-avatar></ion-avatar>
                        <div ion-text color="dhola" class="icon-text">
                            <ion-skeleton-text
                                animated
                                style="width: 60%; margin: 0 auto"
                            ></ion-skeleton-text>
                        </div>
                    </div>
                </ion-col>

                <ion-col size="6" class="ion-text-center">
                    <div class="col-content">
                        <ion-avatar></ion-avatar>
                        <div color="dhola" class="icon-text">
                            <ion-skeleton-text
                                animated
                                style="width: 60%; margin: 0 auto"
                            ></ion-skeleton-text>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ng-template>
