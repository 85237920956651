import { createAction, props } from '@ngrx/store'
import { IBlogPost } from '@store/models/blog.model'

export const GET_BLOG_POST = 'GET_BLOG_POST'
export const GET_BLOG_POST_SUCCESS = 'GET_BLOG_POST_SUCCESS'
export const getBlogPost = createAction(
    GET_BLOG_POST,
    props<{ token: string }>()
)
export const getBlogPostSuccess = createAction(
    GET_BLOG_POST_SUCCESS,
    props<{ payload: IBlogPost }>()
)
