import { AlbumEffects } from './album.effects'
import { AuthEffects } from './auth.effects'
import { BlogEffects } from './blog.effects'
import { DictionaryEffects } from './dictionary.effects'
import { JokeEffects } from './joke.effects'
import { LanguageEffects } from './language.effects'
import { LoaderEffects } from './loader.effects'
import { MandiEffects } from './mandi.effects'
import { PartnerEffects } from './partner.effects'
import { PlayerTypeEffects } from './player-type.effects'
import { PlayerEffects } from './player.effects'
import { PoemEffects } from './poem.effects'
import { ProgramEffects } from './program.effects'
import { ProverbEffects } from './proverb.effects'
import { RequestEffects } from './request.effects'
import { RequestsAreTakenEffects } from './requests-are-taken.effects'
import { SongEffects } from './song.effects'
import { TeamEffects } from './team.effects'
import { TranslationEffects } from './translation.effects'
import { WeatherEffects } from './weather.effects'
import { WriterEffects } from './writer.effects'
import { YoutubeEffects } from './youtube.effects'

export const effects = [
    AlbumEffects,
    AuthEffects,
    BlogEffects,
    DictionaryEffects,
    JokeEffects,
    LanguageEffects,
    LoaderEffects,
    MandiEffects,
    PartnerEffects,
    PlayerTypeEffects,
    PlayerEffects,
    PoemEffects,
    ProgramEffects,
    ProverbEffects,
    RequestEffects,
    RequestsAreTakenEffects,
    SongEffects,
    TeamEffects,
    TranslationEffects,
    WriterEffects,
    YoutubeEffects,
    WeatherEffects,
]
