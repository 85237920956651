import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({ providedIn: 'root' })
export class WeatherService {
    constructor(private http: HttpClient) {}

    getCurrentWeather(latitude: number, longitude: number) {
        const { domain, apiKey } = environment.weather
        const url = `${domain}?appid=${apiKey}&lat=${latitude}&lon=${longitude}&units=metric`

        return this.http.get(url)
    }
}
