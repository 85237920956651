import { Component } from '@angular/core'
import {
    BackgroundMode,
    BackgroundModeConfiguration,
} from '@ionic-native/background-mode/ngx'
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { Platform } from '@ionic/angular'
import { GlobalService } from '@services/global.service'
import { LanguageService, translationLoaded$ } from '@services/language.service'
import { MusicService } from '@services/music.service'
import { PlayerTypeService } from '@services/player-type.service'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent {
    hasCordova: boolean
    isLoaded: boolean

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private backgroundMode: BackgroundMode,
        private googleAnalytics: GoogleAnalytics,
        private musicService: MusicService,
        private languageService: LanguageService,
        private playerTypeService: PlayerTypeService,
        private globalService: GlobalService
    ) {
        this.hasCordova = this.platform.is('cordova')
        this.initializeApp()
    }

    private async initializeApp() {
        await this.platform.ready()

        // wait for translation
        translationLoaded$.subscribe(loaded => {
            this.isLoaded = loaded

            if (this.hasCordova) {
                this.splashScreen.hide()
            }
        })

        // Start language service
        this.languageService.setTranslationLang()

        // Start Player type
        this.playerTypeService.setCurrentPlayerType()

        // Start Global Service
        this.globalService.init()

        // start music service
        this.musicService.getAudioSource()

        if (this.hasCordova) {
            // Okay, so the platform is ready and our plugins are available.
            // Here you can do any higher level native things you might need.
            this.statusBar.backgroundColorByHexString('#ff7733')
            this.statusBar.styleLightContent()

            // set analytics
            this.setAnalytics()

            // set background mode
            this.setBackgroundMode()
        }
    }

    private async setAnalytics() {
        await this.googleAnalytics.startTrackerWithId('UA-79794814-1')
        this.googleAnalytics.trackView('RadioKasootApp')
    }

    private setBackgroundMode() {
        // define options
        const options: BackgroundModeConfiguration = {
            title: 'Radio Kasoot',
            text: 'Arun Malik',
            icon: 'notification_icon',
        }

        // set default options
        this.backgroundMode.setDefaults(options)

        // set background mode
        this.backgroundMode.enable()

        //
        this.backgroundMode.on('activate').subscribe(() => {
            this.backgroundMode.disableWebViewOptimizations()
        })
    }
}
