import { Component, Input } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { IRecord } from '@app/store/models/mandi.model'

@Component({
    selector: 'app-mandi-details',
    templateUrl: './mandi-details.page.html',
    styleUrls: ['./mandi-details.page.scss'],
})
export class MandiDetailsPage {
    @Input() title: string
    @Input() records: IRecord[]

    constructor(private modalCtrl: ModalController) {}

    dismiss() {
        this.modalCtrl.dismiss()
    }
}
