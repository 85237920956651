import { Component, Input } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { IPoem } from '@app/store/models/poem.model'

@Component({
    selector: 'app-poem',
    templateUrl: './poem.page.html',
    styleUrls: ['./poem.page.scss'],
})
export class PoemPage {
    @Input() poem: {
        payload: IPoem
    }

    constructor(private modalCtrl: ModalController) {}

    dismiss() {
        this.modalCtrl.dismiss()
    }
}
