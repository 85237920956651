import { Injectable } from '@angular/core'
import { ITranslatedValues } from '@app/interfaces'
import { AlertController } from '@ionic/angular'
import { AlertInput } from '@ionic/core'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import {
    setCurrentLanguage,
    setDefaultLanguage,
    setLanguages,
} from '@store/actions/language.actions'
import { getTranslations } from '@store/actions/translation.actions'
import { ILanguage } from '@store/models/language.model'
import { ITranslation } from '@store/models/translation.model'
import { IAppState } from '@store/state'
import { BehaviorSubject } from 'rxjs'
import { take } from 'rxjs/operators'
import { StorageService } from './storage.service'

const currentLangPath = 'currentLang'
export const translationLoaded$ = new BehaviorSubject<boolean>(false)

@Injectable({ providedIn: 'root' })
export class LanguageService {
    currentLang: ILanguage
    defaultLang: ILanguage
    languages: ILanguage[]
    translations: ITranslation

    constructor(
        private translateService: TranslateService,
        private alertCtrl: AlertController,
        private storage: StorageService,
        private store: Store<IAppState>
    ) {
        this.languages = [
            { value: 'hi', label: 'Haryanvi' },
            { value: 'en', label: 'English' },
            { value: 'ur', label: 'Urdu' },
        ]

        this.defaultLang = this.languages.find(
            (lang: ILanguage) => lang.value === 'hi'
        )

        // start translation
        this.translateService.setDefaultLang(this.defaultLang.value)
        // Set default language
        this.setLang(this.defaultLang)
        this.store.dispatch(setLanguages({ payload: this.languages }))
        this.store.dispatch(setDefaultLanguage({ lang: this.defaultLang }))
        this.store.dispatch(setCurrentLanguage({ lang: this.defaultLang }))
        this.store.dispatch(getTranslations())
        this.store.select('translations').subscribe(translations => {
            this.translations = translations
            this.setTranlations()
        })
    }

    async setTranslationLang(): Promise<void> {
        // get current lang
        this.currentLang = await this.getCurrentLang()

        // if current lang is present
        if (!!this.currentLang) {
            this.store.dispatch(setCurrentLanguage({ lang: this.currentLang }))

            // set language to saved
            this.setTranlations()

            const direction = this.currentLang.value === 'ur' ? 'rtl' : 'ltr'
            this.setDirection(direction)
        }
    }

    langPopup(): void {
        const translationKeys: Array<string> = [
            'LANGUAGE',
            'ALERTS.LANGUAGE_CHANGE',
            'ALERTS.LANGUAGE_DONT_CHANGE',
        ]

        this.translateService
            .get(translationKeys)
            .subscribe(async (values: ITranslatedValues) => {
                const header = values['LANGUAGE'],
                    yesChange = values['ALERTS.LANGUAGE_CHANGE'],
                    dontChange = values['ALERTS.LANGUAGE_DONT_CHANGE']

                const alert = await this.alertCtrl.create({
                    header,
                    inputs: this.languages.map(
                        (lang: ILanguage) =>
                            ({
                                type: 'radio',
                                label: lang.label,
                                value: lang,
                                checked:
                                    this.currentLang &&
                                    this.currentLang.value === lang.value,
                            } as AlertInput)
                    ),
                    buttons: [
                        { text: dontChange },
                        {
                            text: yesChange,
                            handler: (lang: ILanguage) => this.setLang(lang),
                        },
                    ],
                })

                await alert.present()
            })
    }

    private setTranlations() {
        if (this.translations && this.currentLang) {
            this.translateService
                .reloadLang(this.currentLang.value)
                .pipe(take(1))
                .subscribe(() => {
                    this.translateService.use(this.currentLang.value)
                    translationLoaded$.next(true)
                })
        }
    }

    private async setLang(lang: ILanguage): Promise<void> {
        await this.storage.set(currentLangPath, lang)
        this.setTranslationLang()
    }

    private async getCurrentLang(): Promise<ILanguage> {
        return this.storage.get(currentLangPath)
    }

    private setDirection(direction: string) {
        document.documentElement.setAttribute('dir', direction)
    }
}
