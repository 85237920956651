import { Injectable } from '@angular/core'
import { AlertController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { MusicService } from './music.service'
import { Network } from '@ionic-native/network/ngx'
import { merge, distinct, distinctUntilChanged } from 'rxjs/operators'

export interface IConnection {
    type: string
}

const getValue = (vals: object, key: string) => vals[key] !== key && vals[key]

@Injectable({ providedIn: 'root' })
export class NetworkService {
    // disconnection
    private disconnectionAlert: HTMLIonAlertElement
    private disconnectionTitle: string
    private disconnectionMessage: string
    private disconnectionButton: string

    constructor(
        private alertCtrl: AlertController,
        private translateService: TranslateService,
        private musicService: MusicService,
        private network: Network
    ) {}

    async init(): Promise<void> {
        // get connection translations
        this.getConnectionTranslations()

        // init networtk status
        this.startTrackingNetworkStatus()
    }

    getConnectionTranslations(): void {
        const translationKeys: Array<string> = [
            'ALERTS.DISCONNECTION_TITLE',
            'ALERTS.DISCONNECTION_MESSAGE',
            'ALERTS.DISCONNECTION_BUTTON',
        ]

        this.translateService.get(translationKeys).subscribe(vals => {
            this.disconnectionTitle = getValue(
                vals,
                'ALERTS.DISCONNECTION_TITLE'
            )
            this.disconnectionMessage = getValue(
                vals,
                'ALERTS.DISCONNECTION_MESSAGE'
            )
            this.disconnectionButton = getValue(
                vals,
                'ALERTS.DISCONNECTION_BUTTON'
            )
        })
    }

    startTrackingNetworkStatus(): void {
        const connectionSub: Observable<any> = this.network.onConnect()
        const disConnectionSub: Observable<any> = this.network.onDisconnect()

        connectionSub
            .pipe(
                merge(disConnectionSub),
                distinctUntilChanged(
                    (c: IConnection, d: IConnection) => c.type === d.type
                )
            )
            .subscribe((connection: IConnection) =>
                connection.type === 'online'
                    ? this.onNetworkConnect()
                    : this.onNetworkDisconnect()
            )
    }

    async onNetworkConnect() {
        // watch network for a connection
        if (!this.musicService.manuallyPaused && this.musicService.playedOnce) {
            this.musicService.play(true)
        }

        if (!!this.disconnectionAlert) {
            this.disconnectionAlert.dismiss()
        }
    }

    async onNetworkDisconnect() {
        // watch network for a connection
        this.musicService.play(false)

        if (!!this.disconnectionAlert) {
            this.disconnectionAlert.dismiss()
        }

        this.disconnectionAlert = await this.alertCtrl.create({
            header: this.disconnectionTitle,
            message: this.disconnectionMessage,
            buttons: [this.disconnectionButton],
        })

        if (this.disconnectionTitle) {
            await this.disconnectionAlert.present()
        }
    }
}
