import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, ofType, createEffect } from '@ngrx/effects'
import {
    getServerSuccess,
    getSongTitleSuccess,
    GET_SERVER,
    GET_SONG_TITLE,
    updateIsPlayingSuccess,
    updateStreamSuccess,
    UPDATE_IS_PLAYING,
    UPDATE_STREAM,
} from '@store/actions/player.actions'
import { IIcecastData, IServer } from '@store/models/player.model'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class PlayerEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase,
        private http: HttpClient
    ) {}

    getServer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_SERVER),
            switchMap(() => this.database.object('server').valueChanges()),
            map((payload: IServer) => getServerSuccess({ payload }))
        )
    )

    getSongTitle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_SONG_TITLE),
            switchMap(({ statsUrl }) => this.http.get(statsUrl)),
            map((payload: IIcecastData) => getSongTitleSuccess({ payload }))
        )
    )

    updatePlaying$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_IS_PLAYING),
            map(({ isPlaying }) => updateIsPlayingSuccess({ isPlaying }))
        )
    )

    updateStream$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_STREAM),
            map(({ payload }) => updateStreamSuccess({ payload }))
        )
    )
}
