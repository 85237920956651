import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

const { domain, apiKey } = environment.mandi_prices
const args = `format=json&limit=1000&offset=0&filters[state]=Haryana&fields=district,market,modal_price,commodity`

@Injectable({ providedIn: 'root' })
export class MandiService {
    constructor(public http: HttpClient) {}

    getMandiPrices() {
        return this.http.get(`${domain}?api-key=${apiKey}&${args}`)
    }
}
