import { Action, createAction, props } from '@ngrx/store'
import { ISnapshot } from '@store/state'
import { IWord } from '@store/models/dictionary.model'

export const GET_FIRST_WORD = 'GET_FIRST_WORD'
export const GET_FIRST_WORD_SUCCESS = 'GET_FIRST_WORD_SUCCESS'
export const GET_NEXT_WORD = 'GET_NEXT_WORD'
export const GET_NEXT_WORD_SUCCESS = 'GET_NEXT_WORD_SUCCESS'
export const GET_PREVIOUS_WORD = 'GET_PREVIOUS_WORD'
export const GET_PREVIOUS_WORD_SUCCESS = 'GET_PREVIOUS_WORD_SUCCESS'
export const CREATE_WORD = 'CREATE_WORD'
export const CREATE_WORD_SUCCESS = 'CREATE_WORD_SUCCESS'
export const getFirstWord = createAction(GET_FIRST_WORD)
export const getFirstWordSuccess = createAction(
    GET_FIRST_WORD_SUCCESS,
    props<{ payload: ISnapshot[] }>()
)
export const getNextWord = createAction(GET_NEXT_WORD, props<{ key: string }>())
export const getNextWordSuccess = createAction(
    GET_NEXT_WORD_SUCCESS,
    props<{ payload: ISnapshot[] }>()
)
export const getPreviousWord = createAction(
    GET_PREVIOUS_WORD,
    props<{ key: string }>()
)
export const getPreviousWordSuccess = createAction(
    GET_PREVIOUS_WORD_SUCCESS,
    props<{ payload: ISnapshot[] }>()
)
export const createWord = createAction(CREATE_WORD, props<{ payload: IWord }>())
export const createWordSuccess = createAction(CREATE_WORD_SUCCESS)
