import { AngularFireDatabase } from '@angular/fire/database'
import { Store } from '@ngrx/store'
import { TranslateLoader } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IAppState } from '@store/state'

class FirebaseTransLoader implements TranslateLoader {
    constructor(private db: AngularFireDatabase) {}

    public getTranslation(
        lang: string,
        path: string = 'translations/'
    ): Observable<any> {
        return this.db.object(`${path}${lang}`).valueChanges()
    }
}

class ReduxTransLoader implements TranslateLoader {
    constructor(private store: Store<IAppState>) {}

    public getTranslation(lang: string): Observable<any> {
        return this.store
            .select('translations')
            .pipe(map(translations => translations && translations[lang]))
    }
}

export const FBTransfactory = (db: AngularFireDatabase) =>
    new FirebaseTransLoader(db)

export const ReduxTransFactory = (store: Store<IAppState>) =>
    new ReduxTransLoader(store)

// Example for custom loader
// class CustomLoader implements TranslateLoader {
//     getTranslation(lang: string): Observable<any> {
//         return Observable.of({ KEY: 'value' });
//     }
// }
