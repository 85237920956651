import { createAction, props } from '@ngrx/store'
import { ITeam } from '@store/models/team.model'

export const GET_TEAM = 'GET_TEAM'
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS'
export const getTeam = createAction(GET_TEAM)
export const getTeamSuccess = createAction(
    GET_TEAM_SUCCESS,
    props<{ payload: ITeam[] }>()
)
