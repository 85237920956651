import { Component } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { IAppState } from '@store/state'
import { IRequest } from '@store/models/request.model'
import { createRequest } from '@store/actions/request.actions'
import { ITranslatedValues } from '@app/interfaces'

@Component({
    selector: 'app-add-request',
    templateUrl: './add-request.page.html',
    styleUrls: ['./add-request.page.scss'],
})
export class AddRequestPage {
    public request: IRequest = {
        title: '',
        name: '',
        address: '',
        message: '',
        contact: '',
    }

    constructor(
        public modalCtrl: ModalController,
        private alertCtrl: AlertController,
        private translateService: TranslateService,
        private store: Store<IAppState>
    ) {}

    saveRequest() {
        if (this.request.title) {
            this.store.dispatch(createRequest({ payload: this.request }))
            this.dismiss()
        } else {
            this.showError()
        }
    }

    dismiss() {
        this.modalCtrl.dismiss()
    }

    showError() {
        const translationKeys: Array<string> = [
            'ALERTS.JOKE_ERROR_TITLE',
            'ALERTS.JOKE_ERROR_MESSAGE',
            'ALERTS.JOKE_ERROR_BUTTON',
        ]

        this.translateService
            .get(translationKeys)
            .subscribe(async (values: ITranslatedValues) => {
                const header = values['ALERTS.JOKE_ERROR_TITLE'],
                    message = values['ALERTS.JOKE_ERROR_MESSAGE'],
                    button = values['ALERTS.JOKE_ERROR_BUTTON']

                const alert = await this.alertCtrl.create({
                    header,
                    message,
                    buttons: [button],
                })

                await alert.present()
            })
    }
}
