import { Injectable } from '@angular/core'
import {
    Media,
    MediaObject,
    MEDIA_ERROR,
    MEDIA_STATUS,
} from '@ionic-native/media/ngx'
import {
    MusicControls,
    MusicControlsOptions,
} from '@ionic-native/music-controls/ngx'
import { AlertController, Platform } from '@ionic/angular'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { get } from 'lodash'
import {
    getServer,
    updateIsPlaying,
    updateStream,
} from '@store/actions/player.actions'
import { IPlayerTypeContainer } from '@store/models/player-type.model'
import { IStream } from '@store/models/player.model'
import { IAppState } from '@store/state'

const radioKasootIconUrl =
    'https://graph.facebook.com/331985113591743/picture?type=large'

const musicControlsOptions: MusicControlsOptions = {
    track: 'Live Radio',
    artist: 'Arun Malik',
    cover: radioKasootIconUrl,
    isPlaying: false,
    dismissable: true,

    // hide previous/next/close buttons:
    hasPrev: false,
    hasNext: false,
    hasClose: false,

    // iOS only, optional
    album: 'Radio Kasoot - First Haryanvi Radio',
    hasSkipForward: false,
    hasSkipBackward: false,

    // Android only, optional
    // text displayed in the status bar when the notification (and the ticker) are updated
    ticker: 'Now playing "Live Radio"',

    // Icons
    playIcon: 'play_icon',
    pauseIcon: 'stop_icon',
    notificationIcon: 'notification_icon',
}

@Injectable({ providedIn: 'root' })
export class MusicService {
    public manuallyPaused: boolean
    public playedOnce: boolean
    private audio: HTMLAudioElement | MediaObject
    private audioSrc: string
    private hasCordova: boolean
    private isWebPlayer: boolean
    private currentStream: IStream

    constructor(
        private platform: Platform,
        private alertCtrl: AlertController,
        private media: Media,
        private musicControls: MusicControls,
        private translateService: TranslateService,
        private store: Store<IAppState>
    ) {
        // has cordova
        this.hasCordova = this.platform.is('cordova')

        this.store
            .select('playerTypes')
            .subscribe((playerTypes: IPlayerTypeContainer) => {
                this.isWebPlayer = get(playerTypes, 'current.value') === 'web'

                if (this.hasCordova && this.isWebPlayer) {
                    this.play(false)
                    this.switchPlayer()
                }
            })

        // initialize the audio
        this.init()
    }

    async init() {
        await this.platform.ready()
        this.hasCordova ? this.initControls() : this.setupHtmlPlayer()
    }

    getAudioSource() {
        this.store.dispatch(getServer())
    }

    selectStream(stream: IStream) {
        this.store.dispatch(updateStream({ payload: stream }))
        this.currentStream = stream
        this.audioSrc = stream.url
        this.updateControls()
        this.play(true)
    }

    setSrc(src: string) {
        this.audioSrc = src
    }

    play(play: boolean, manual = false) {
        // set if the pause is manual
        this.manuallyPaused = manual && !play

        if (!this.audioSrc) {
            return
        }

        // don't do anything if
        // 1. manually paused
        // 2. user never started stream
        if (play && !manual && this.manuallyPaused) {
            return
        }

        this.stop()

        // Play or pause based on what we want
        if (play) {
            this.playedOnce = true

            // Update the source, reinit audio

            this.updateSource()
            this.audio.play()
        }

        // Update status
        this.updateIsPlaying(play)

        // Update music control status
        this.updateMusicControlPlaying(play)
    }

    private switchPlayer() {
        if (this.isWebPlayer) {
            this.setupHtmlPlayer()
        }
    }

    private setupHtmlPlayer() {
        this.audio = document.getElementById('live-radio') as HTMLAudioElement
        this.audio.onwaiting = this.onWaiting.bind(this)
        this.audio.onplaying = this.onPlaying.bind(this)
        this.audio.onended = this.onEnded.bind(this)
        this.audio.onerror = this.onError.bind(this)
        this.audio.onpause = this.onPause.bind(this)
    }

    private setupNativePlayer() {
        this.audio = this.media.create(this.audioSrc)
        // fire status change event
        this.audio.onStatusUpdate.subscribe(this.onStatusUpdate.bind(this))
        this.audio.onError.subscribe(this.onMediaError.bind(this))
    }

    private initControls() {
        // add music controls on device
        this.musicControls.create({
            ...musicControlsOptions,
            track:
                (this.currentStream && this.currentStream.label) ||
                'Live Radio',
        })
        this.musicControls.subscribe().subscribe(this.events.bind(this))
        this.musicControls.listen() // activates the observable above
    }

    private updateControls() {
        this.musicControls.destroy()
        this.initControls()
    }

    private updateSource() {
        if (this.hasCordova && !this.isWebPlayer) {
            this.setupNativePlayer()
        } else {
            if ('src' in this.audio && this.audioSrc) {
                this.audio.src = this.audioSrc
            }
        }
    }

    private stop() {
        // pause audio unless audio is not defined
        if (!!this.audio) {
            this.audio.pause()
        }

        if (!!this.audio && 'stop' in this.audio && 'release' in this.audio) {
            this.audio.stop()
            this.audio.release()
        }
    }

    private events(action: string) {
        const message = JSON.parse(action).message

        switch (message) {
            case 'music-controls-pause':
                this.play(false)
                break
            case 'music-controls-play':
                this.play(true)
                break
            // External controls (iOS only)
            case 'music-controls-toggle-play-pause':
                this.play(true)
                break
            default:
                break
        }
    }

    private showError() {
        const translationKeys: Array<string> = [
            'ALERTS.MUSIC_ERROR_TITLE',
            'ALERTS.MUSIC_ERROR_MESSAGE',
            'ALERTS.MUSIC_ERROR_BUTTON',
        ]

        this.translateService
            .get(translationKeys)
            .subscribe(async (values: object[]) => {
                const header: string = values['ALERTS.MUSIC_ERROR_TITLE'],
                    message: string = values['ALERTS.MUSIC_ERROR_MESSAGE'],
                    button: string = values['ALERTS.MUSIC_ERROR_BUTTON']

                const alert = await this.alertCtrl.create({
                    header,
                    message,
                    buttons: [button],
                })

                await alert.present()
            })
    }

    private updateIsPlaying(isPlaying: boolean) {
        this.store.dispatch(updateIsPlaying({ isPlaying }))
    }

    private updateMusicControlPlaying(value: boolean) {
        if (this.hasCordova) {
            this.musicControls.updateIsPlaying(value)
        }
    }

    private onStatusUpdate(status: MEDIA_STATUS) {
        console.log({ status })
        switch (status) {
            case MEDIA_STATUS.NONE:
                this.onError()
                break
            case MEDIA_STATUS.STARTING:
                this.onWaiting()
                break
            case MEDIA_STATUS.RUNNING:
                this.onPlaying()
                break
            case MEDIA_STATUS.STOPPED:
                this.onPause()
                break
            default:
                break
        }
    }

    private onMediaError(error: any) {
        const code: MEDIA_ERROR = error.code
        console.log({ code })

        switch (code) {
            case MEDIA_ERROR.ABORTED:
                this.onComplete()
                break
            case MEDIA_ERROR.NETWORK:
                this.onError()
                break
            case MEDIA_ERROR.DECODE:
                this.onError()
                break
            case MEDIA_ERROR.SUPPORTED:
                this.onError()
                break
            default:
                break
        }
    }

    private onComplete() {
        console.log('On Complete')

        this.stop()

        if (!this.manuallyPaused) {
            console.log('Not Manual Pause')

            // play audio
            this.play(true)
        } else {
            console.log('Manual Pause')
        }
    }

    private onWaiting() {
        console.log('Now Buffering')
    }

    private onPlaying() {
        console.log('Now Playing')
        this.updateIsPlaying(true)
    }

    private onEnded() {
        console.log('Stream Has ended')
        this.play(true)
    }

    private onError() {
        console.log('An error Occured')
        this.updateIsPlaying(false)
        this.showError()
    }

    private onPause() {
        console.log('Now Paused')
        this.updateIsPlaying(false)
    }
}
