import { createReducer, on } from '@ngrx/store'
import * as Actions from '@store/actions/auth.actions'
import { get } from 'lodash'

export default createReducer(
    undefined,
    on(Actions.loginSuccess, (state, { payload }) => {
        const currentUser = payload
        const displayName = get(currentUser, 'displayName')
        const avatarId = get(currentUser, 'providerData.0.uid')
        const avatarUrl = `https://graph.facebook.com/${avatarId}/picture`

        return {
            ...state,
            loggedIn: true,
            avatarId,
            avatarUrl,
            displayName,
        }
    }),
    on(Actions.logoutSuccess, state => {
        return {
            ...state,
            loggedIn: false,
            displayName: null,
            avatarId: null,
            avatarUrl: null,
        }
    })
)
