import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { AppAvailability } from '@ionic-native/app-availability/ngx'
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { Platform } from '@ionic/angular'
import { ShakeService } from '@services/shake.service'
import { NetworkService } from './network.service'
import { RatingService } from './rating.service'
import { NotificationService } from './notification.service'
import { LoadingService } from './loading.service'
import { TranslateService } from '@ngx-translate/core'

@Injectable({ providedIn: 'root' })
export class GlobalService {
    public logoImageUrl =
        'https://graph.facebook.com/331985113591743/picture?type=large'
    private isCordova: boolean

    constructor(
        private title: Title,
        private inAppBrowser: InAppBrowser,
        private appAvailability: AppAvailability,
        private googleAnalytics: GoogleAnalytics,
        private platform: Platform,
        private shakeService: ShakeService,
        private networkService: NetworkService,
        private ratingService: RatingService,
        private loadingService: LoadingService,
        private translateService: TranslateService,
        private notificationService: NotificationService
    ) {
        this.isCordova = this.platform.is('cordova')
    }

    async init(): Promise<void> {
        // init networtk status
        this.networkService.init()

        // Init Loader
        this.loadingService.init()

        // set app rating
        await this.platform.ready()

        if (this.isCordova) {
            // Subscribe for notifications
            this.notificationService.subscribe()

            // Rate Service
            this.ratingService.setAppRating()

            // watch shake events
            this.shakeService.watchShake()
        }

        this.translateService.onLangChange.subscribe(() =>
            this.networkService.getConnectionTranslations()
        )
    }

    trackView(pageTitle: string): void {
        this.googleAnalytics.trackView(pageTitle)
    }

    async setTitle(title: string = 'Radio Kasoot'): Promise<void> {
        await this.platform.ready()
        const pageTitle = `${title} - Radio Kasoot First Haryanvi Radio`
        this.title.setTitle(pageTitle)

        if (this.isCordova) {
            this.trackView(pageTitle)
        }
    }

    openSystemORBrowser(
        scheme: string,
        visitUrl: string,
        website: string
    ): void {
        if (!this.isCordova) {
            this.inAppBrowser.create(encodeURI(website), '_system')
            return
        }

        this.appAvailability.check(scheme).then(
            () => {
                // open the system url
                this.inAppBrowser.create(encodeURI(visitUrl), '_system')
            },
            () => {
                // open the website
                const browser = this.inAppBrowser.create(
                    encodeURI(visitUrl),
                    '_blank'
                )

                browser.on('loaderror').subscribe(() => {
                    this.inAppBrowser.create(encodeURI(website), '_system')
                    browser.close()
                })
            }
        )
    }

    openInBrowser(url: string): void {
        this.inAppBrowser.create(encodeURI(url), '_system')
    }
}
