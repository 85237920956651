import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, ofType, createEffect } from '@ngrx/effects'
import {
    createJokeSuccess,
    CREATE_JOKE,
    getFirstJokeSuccess,
    getNextJokeSuccess,
    getPreviousJokeSuccess,
    GET_FIRST_JOKE,
    GET_NEXT_JOKE,
    GET_PREVIOUS_JOKE,
} from '@store/actions/joke.actions'
import { ISnapshot } from '@store/state'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class JokeEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {}

    getFirstJoke$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_FIRST_JOKE),
            switchMap(() =>
                this.database
                    .list('jokes', ref =>
                        ref
                            .orderByChild('approved')
                            .equalTo(true)
                            .limitToLast(2)
                    )
                    .snapshotChanges()
            ),
            map((payload: ISnapshot[]) => getFirstJokeSuccess({ payload }))
        )
    )

    getNextJoke$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_NEXT_JOKE),
            switchMap(({ key }) =>
                this.database
                    .list('jokes', ref =>
                        ref
                            .orderByKey()
                            .endAt(key)
                            .limitToLast(2)
                    )
                    .snapshotChanges()
            ),
            map((payload: ISnapshot[]) => getNextJokeSuccess({ payload }))
        )
    )

    getPreviousJoke$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_PREVIOUS_JOKE),
            switchMap(({ key }) =>
                this.database
                    .list('jokes', ref =>
                        ref
                            .orderByKey()
                            .startAt(key)
                            .limitToFirst(2)
                    )
                    .snapshotChanges()
            ),
            map((payload: ISnapshot[]) => getPreviousJokeSuccess({ payload }))
        )
    )

    createJoke$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CREATE_JOKE),
            switchMap(({ payload }) =>
                this.database.list('jokes').push(payload)
            ),
            map(() => createJokeSuccess())
        )
    )
}
