import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
    setCurrentPlayerTypeSuccess,
    setDefaultPlayerTypeSuccess,
    setPlayerTypeSuccess,
    SET_CURRENT_PLAYER_TYPE,
    SET_DEFAULT_PLAYER_TYPE,
    SET_PLAYER_TYPE,
} from '@store/actions/player-type.actions'
import { map } from 'rxjs/operators'

@Injectable()
export class PlayerTypeEffects {
    constructor(private actions$: Actions) {}

    setPlayerTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SET_PLAYER_TYPE),
            map(({ payload }) => setPlayerTypeSuccess({ payload }))
        )
    )

    setDefaultPlayerType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SET_DEFAULT_PLAYER_TYPE),
            map(({ playerType }) => setDefaultPlayerTypeSuccess({ playerType }))
        )
    )

    setCurrentPlayerType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SET_CURRENT_PLAYER_TYPE),
            map(({ playerType }) => setCurrentPlayerTypeSuccess({ playerType }))
        )
    )
}
