import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
    getPartnersSuccess,
    GET_PARTNERS,
} from '@store/actions/partner.actions'
import { IPartner } from '@store/models/partner.model'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class PartnerEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {}

    getPartners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_PARTNERS),
            switchMap(() => this.database.list('partners').valueChanges()),
            map((payload: IPartner[]) => getPartnersSuccess({ payload }))
        )
    )
}
