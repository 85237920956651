import { Injectable } from '@angular/core'
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects'
import { Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { WeatherService } from '@services/weather.service'
import {
    GET_WEATHER,
    GET_WEATHER_DESCRIPTION,
    getWeatherDescriptionSuccess,
    getWeatherSuccess,
} from '@store/actions/weather.actions'
import { IWeather, IWeatherDescription } from '@store/models/weather.model'
import { AngularFireDatabase } from '@angular/fire/database'

@Injectable()
export class WeatherEffects {
    constructor(
        private actions$: Actions,
        private weatherService: WeatherService,
        private db: AngularFireDatabase
    ) {}

    getWeather$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_WEATHER),
            switchMap(({ latitude, longitude }) =>
                this.weatherService.getCurrentWeather(latitude, longitude)
            ),
            map((payload: IWeather) => getWeatherSuccess(payload))
        )
    )

    getWeatherDescription$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_WEATHER_DESCRIPTION),
            switchMap(({ code }) =>
                this.db.object(`weather/${code}`).valueChanges()
            ),
            map((payload: IWeatherDescription) =>
                getWeatherDescriptionSuccess(payload)
            )
        )
    )
}
