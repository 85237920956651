import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { getSongsSuccess, GET_SONGS } from '@store/actions/song.actions'
import { ISongPayload } from '@store/models/song.model'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class SongEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {}

    getSongs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_SONGS),
            switchMap(({ albumKey }) =>
                this.database
                    .list('/songs', ref =>
                        ref.orderByChild('albumKey').equalTo(albumKey)
                    )
                    .snapshotChanges()
                    .pipe(
                        map(snapshot =>
                            snapshot.map(song => ({
                                key: song.payload.key,
                                payload: song.payload.val(),
                            }))
                        )
                    )
            ),
            map((payload: ISongPayload[]) => getSongsSuccess({ payload }))
        )
    )
}
