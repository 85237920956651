import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { getWritersSuccess, GET_WRITERS } from '@store/actions/writer.actions'
import { IWriterPayload } from '@store/models/writer.model'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class WriterEffects {
    constructor(
        private actions$: Actions,
        private database: AngularFireDatabase
    ) {}

    getWriters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GET_WRITERS),
            switchMap(() =>
                this.database
                    .list('writers')
                    .snapshotChanges()
                    .pipe(
                        map(snapshot =>
                            snapshot.map(({ payload }) => ({
                                key: payload.key,
                                payload: payload.val(),
                            }))
                        )
                    )
            ),
            map((writers: IWriterPayload[]) => getWritersSuccess({ writers }))
        )
    )
}
