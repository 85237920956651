import { Action, createAction, props } from '@ngrx/store'
import { IServer, IStream, IIcecastData } from '@store/models/player.model'

export const GET_SERVER = 'GET_SERVER'
export const GET_SERVER_SUCCESS = 'GET_SERVER_SUCCESS'
export const GET_SONG_TITLE = 'GET_SONG_TITLE'
export const GET_SONG_TITLE_SUCCESS = 'GET_SONG_TITLE_SUCCESS'
export const UPDATE_IS_PLAYING = 'UPDATE_IS_PLAYING'
export const UPDATE_IS_PLAYING_SUCCESS = 'UPDATE_IS_PLAYING_SUCCESS'
export const UPDATE_STREAM = 'UPDATE_STREAM'
export const UPDATE_STREAM_SUCCESS = 'UPDATE_STREAM_SUCCESS'
export const getServer = createAction(GET_SERVER)
export const getServerSuccess = createAction(
    GET_SERVER_SUCCESS,
    props<{ payload: IServer }>()
)
export const getSongTitle = createAction(
    GET_SONG_TITLE,
    props<{ statsUrl: string }>()
)
export const getSongTitleSuccess = createAction(
    GET_SONG_TITLE_SUCCESS,
    props<{ payload: IIcecastData }>()
)
export const updateIsPlaying = createAction(
    UPDATE_IS_PLAYING,
    props<{ isPlaying: boolean }>()
)
export const updateIsPlayingSuccess = createAction(
    UPDATE_IS_PLAYING_SUCCESS,
    props<{ isPlaying: boolean }>()
)
export const updateStream = createAction(
    UPDATE_STREAM,
    props<{ payload: IStream }>()
)
export const updateStreamSuccess = createAction(
    UPDATE_STREAM_SUCCESS,
    props<{ payload: IStream }>()
)
