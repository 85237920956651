import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx'
import { Platform, AlertController } from '@ionic/angular'
import { Store } from '@ngrx/store'
import * as firebase from 'firebase/app'
import { loginSuccess, logoutSuccess } from '@store/actions/auth.actions'
import { IAppState } from '@store/state'
import { TranslateService } from '@ngx-translate/core'
import { ITranslatedValues } from '@app/interfaces'

@Injectable()
export class AuthService {
    constructor(
        private alertCtrl: AlertController,
        private afAuth: AngularFireAuth,
        private facebook: Facebook,
        private platform: Platform,
        private translateService: TranslateService,
        private store: Store<IAppState>
    ) {
        this.afAuth.authState.subscribe((user: any) =>
            user
                ? this.store.dispatch(loginSuccess({ payload: user }))
                : this.store.dispatch(logoutSuccess())
        )
    }

    async promptForLogin() {
        const translationKeys: Array<string> = [
            'BUTTONS.NOT_LOGGED_IN',
            'BUTTONS.LOGIN',
            'APP_RATE.RATE_BUTTON',
            'ALERTS.LANGUAGE_DONT_CHANGE',
        ]

        this.translateService
            .get(translationKeys)
            .subscribe(async (values: ITranslatedValues) => {
                const header = values['BUTTONS.NOT_LOGGED_IN'],
                    message = values['BUTTONS.LOGIN'],
                    yesChange = values['APP_RATE.RATE_BUTTON'],
                    dontChange = values['ALERTS.LANGUAGE_DONT_CHANGE']

                const alert = await this.alertCtrl.create({
                    header,
                    message,
                    buttons: [
                        {
                            text: dontChange,
                        },
                        {
                            text: yesChange,
                            handler: () => this.facebookLogin(),
                        },
                    ],
                })

                await alert.present()
            })
    }

    async facebookLogin(): Promise<any> {
        if (!this.platform.is('cordova')) {
            return this.browserLogin()
        }

        try {
            const response: FacebookLoginResponse = await this.facebook.login([
                'public_profile',
            ])
            const facebookCredential = firebase.auth.FacebookAuthProvider.credential(
                response.authResponse.accessToken
            )
            return this.afAuth.auth.signInWithCredential(facebookCredential)
        } catch (error) {
            this.firebaseFailed(error)
        }
    }

    browserLogin(): void {
        firebase
            .auth()
            .signInWithRedirect(new firebase.auth.FacebookAuthProvider())
    }

    logout(): Promise<void> {
        return this.afAuth.auth.signOut()
    }

    firebaseFailed(error: firebase.FirebaseError) {
        console.log('Firebase failure: ' + JSON.stringify(error))
    }
}
