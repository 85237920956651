import { createReducer, on } from '@ngrx/store'
import * as Actions from '@store/actions/language.actions'

export default createReducer(
    undefined,
    on(Actions.setLanguagesSuccess, (state, { payload }) => ({
        ...state,
        all: payload,
    })),
    on(Actions.setDefaultLanguageSuccess, (state, { lang }) => ({
        ...state,
        default: lang,
    })),
    on(Actions.setCurrentLanguageSuccess, (state, { lang }) => ({
        ...state,
        current: lang,
    }))
)
