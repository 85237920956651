import { createReducer, on } from '@ngrx/store'
import * as Actions from '@store/actions/youtube.actions'

export default createReducer(
    undefined,
    on(Actions.getYoutubeSuccess, (state, { payload }) => ({
        nextPageToken: payload.nextPageToken,
        items: [...(state ? state.items : []), ...payload.items],
    }))
)
